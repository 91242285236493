import React, { useMemo } from "react";
import Drawer from "../Drawer";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import {
  findingAvailableMaterials,
  findingOptions,
} from "../../constants/findings/findingOptions";
import ToothLargerView from "../TeethDiagram/ToothLargerView";
import { getToothLargeViewProps } from "../TeethDiagram/toothLargeViewProps";
import { useTeethStore } from "../../zustand/teethStore";
import { checkShouldApplyFindingOnCrownArea } from "./helper";

const toothLargeviewProps = getToothLargeViewProps();

export default function SubFinding({
  open,
  onClose,
  options,
  onSelectOption,
  onSave,
  selectedOptionPaths,
  onClear,
}) {
  const { activeTeeth, selectedCrownAreas, procCodes } = useTeethStore();
  const showToothLargeView = useMemo(
    () =>
      checkShouldApplyFindingOnCrownArea(
        findingOptions[selectedOptionPaths[0]]?.availableAreas || [],
      ),
    [selectedOptionPaths],
  );

  return (
    // <Drawer open={open} onClose={onClose} width="100%" maxWidth="700px">
    <Stack p={3} height="100%" width={"100%"}>
      <Typography pb={4} variant="h5">
        Select Finding
      </Typography>

      {showToothLargeView && (
        <Stack
          direction="row"
          gap={1}
          alignItems="flex-start"
          justifyContent="center"
          width="100%"
          height="100%"
          sx={{ overflowX: "auto", overflowY: "hidden" }}
        >
          {activeTeeth.map((tooth) => (
            <ToothLargerView
              key={tooth}
              tooth={tooth}
              {...toothLargeviewProps[tooth]}
            />
          ))}
        </Stack>
      )}

      {((showToothLargeView && !!selectedCrownAreas.length) ||
        !showToothLargeView) && (
        <Stack
          height="100%"
          justifyContent={showToothLargeView ? "flex-end" : "space-between"}
          gap="60px"
          marginTop={5}
          alignItems={"center"}
        >
          <Stack gap={3} alignItems={"center"}>
            <Grid gap={1} display="flex" gridTemplateColumns="repeat(3, 1fr)">
              {options.map((optionPath, optionIndex) => {
                const mappedOption = findingAvailableMaterials[optionPath];
                const shouldRender = procCodes?.find(
                  (procCodeData) =>
                    procCodeData?.ProcCode === mappedOption?.procCode,
                );

                if (!shouldRender) {
                  return null;
                }

                const disabled = activeTeeth?.some(
                  (activeTooth) =>
                    !mappedOption?.availablePositions?.includes(activeTooth),
                );

                return (
                  <Box
                    key={`${mappedOption.name}-${optionIndex}`}
                    onClick={() => onSelectOption(mappedOption, optionPath)}
                    p={2}
                    borderRadius="12px"
                    bgcolor={
                      disabled
                        ? grey[400]
                        : selectedOptionPaths[
                            selectedOptionPaths.length - 1
                          ] === optionPath
                        ? blue[900]
                        : blue[400]
                    }
                    color="common.white"
                    sx={{
                      cursor: "pointer",
                      pointerEvents: disabled ? "none" : "all",
                      ":hover": { opacity: 0.8 },
                    }}
                  >
                    {mappedOption.label}
                  </Box>
                );
              })}
            </Grid>

            {selectedOptionPaths.length > 1 && (
              <Button variant="text" size="large" onClick={onClear}>
                Clear
              </Button>
            )}
          </Stack>

          <Button
            variant="contained"
            size="large"
            onClick={onSave}
            fullWidth
            sx={{
              backgroundColor: blue[700],
            }}
          >
            Save
          </Button>
        </Stack>
      )}
    </Stack>
    // {/* </Drawer> */}
  );
}

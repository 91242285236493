import React from 'react';
import './index.css'; // Import the CSS file or include the styles directly in your component

const NotFoundPage = ({title, subTitle, message}) => {
  return (
    <section className="login-wrapper" style={{ background: 'url(https://px-asset.netlify.app/image/background/shortner.jpg) center no-repeat', backgroundSize: 'cover' }}>
      <div className="logiForm">
        <div className="titleInfo">
          <h1 className="title">{title}</h1>
          <p>{subTitle}</p>
        </div>
        <div className="loginInput">
          <p style={{ color: '#ed4337' }}>{message}</p>
        </div>
        <div className="brandLogo">
          <h6>Powered by</h6>
          <a href="https://patientxpress.us"><img src="https://px-asset.netlify.app/image/brand/PX_logo.png" alt="logo" /></a>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
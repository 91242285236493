/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import ExamsListTable from '../../components/ExamsListTable';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { usePerioStore } from '../../zustand/perioStore';
import { fetchPerioExamList } from '../../api/findings';
import { apiCredentials } from '../../api/axios/config';
import { Accordion, AccordionDetails, AccordionSummary } from '../../components/TeethDiagram/common/styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTeethStore } from '../../zustand/teethStore';

const Periodental = () => {
  const navigate = useNavigate();

  const { credentials } = useTeethStore();
  const { setExamsList } = usePerioStore();

  const callFetchPerioExamListApi = async () => {
    try {
      const res = await fetchPerioExamList(credentials?.patientNumber);
      setExamsList(res);
    } catch (error) {
      return navigate(`/403?status=${error?.cause?.response?.status}`);
    }
  };

  const handleCredentialFormSubmit = () => {
    if (credentials.accessToken && credentials.patientNumber) {
      apiCredentials.accessToken = credentials.accessToken;
      callFetchPerioExamListApi();
    }
  };

  useEffect(() => {
    handleCredentialFormSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  if (!(credentials.accessToken && credentials.patientNumber)) {
    return (
      <Navigate
        to='/404'
        replace
      />
    );
  }

  return (
    <>
      <Stack
        direction='row'
        alignItems='center'
        gap='20px'
        justifyContent='center'
        pt={{ xs: 3, md: 5 }}
        mb='10px'
      >
        <Button
          onClick={() => navigate('/tooth-chart')}
          sx={{ color: '#3cbbeb', textTransform: 'capitalize', opacity: 0.4, fontSize: '1.5rem' }}
        >
          Tooth Chart
        </Button>

        <Typography
          textAlign='center'
          variant='h5'
          color='#3cbbeb'
        >
          Periodental
        </Typography>
      </Stack>

      <Box
        px={{ sm: 2, md: 4, lg: 6 }}
        pb={5}
      >
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <Typography fontWeight={700}>Perio Exams List</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ExamsListTable />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
};

export default Periodental;

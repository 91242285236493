import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { Accordion, AccordionDetails, AccordionSummary } from './common/styles';
import { useTeethStore } from '../../zustand/teethStore';

export default function VisualizationIndicators() {
  const { indicatorColors } = useTeethStore();

  return (
    <Accordion
      sx={{ minWidth: '250px', width: '100%', maxWidth: { xs: '100%', lg: '400px' }, mb: { xs: '16px', lg: 'unset' } }}
    >
      <AccordionSummary>
        <Typography fontWeight={700}>Visualization Indicators</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          disablePadding
        >
          {Object.keys(indicatorColors).map((procStatusKey) => {
            const procStatusValue = indicatorColors[procStatusKey];

            return (
              <Fragment key={`${procStatusKey}-${procStatusValue?.color}`}>
                <ListItem alignItems='flex-start'>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        bgcolor: procStatusValue?.color,
                        color: procStatusValue?.textColor,
                        border: `1px solid ${grey[200]}`,
                      }}
                    >
                      1
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${procStatusKey} - ${procStatusValue?.title}`}
                    secondary={procStatusValue?.description}
                  />
                </ListItem>

                <Divider component='li' />
              </Fragment>
            );
          })}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}

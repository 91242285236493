/* eslint-disable react-hooks/exhaustive-deps */
import "./index.css";
import { Box } from "@mui/material";
import React, {
  useEffect,
  useMemo,
  useState,
} from "react";
import { StyledImage } from "./common/StyledImage";
import { ToothNumber } from "./common/ToothNumber";
import { useTeethStore } from "../../zustand/teethStore";
import { StyledToothWrapper } from "./common/StyledToothContainer";
import {
  StyledArea,
  StyledCanvas,
} from "./ToothLargerView";
import {
  readableLowerLeftCrowAreas,
  readableLowerRightCrowAreas,
  readableUpperLeftCrowAreas,
  readableUpperRightCrowAreas,
} from "../../constants/teeth/areas";
import { findingAvailableMaterials } from "../../constants/findings/findingOptions";
import ImageMap from "image-map";
import { getToothLargeViewProps } from "./toothLargeViewProps";
import {
  lowerJawLeftOverlayTeeth,
  upperJawOverlayTeeth,
  upperJawRightOverlayTeeth,
  upperJawTeeth,
} from "../../constants/teeth/tooth";
import {
  getToothDisplayNumber,
  hasProcedureDeleted,
} from "./helper";

const toothLargeviewProps =
  getToothLargeViewProps();

export default function Tooth({
  toothImage,
  crownAreaImage,
  crownPartImage,
  tooth,
  right,
  lowerJaw,
  toothImageWrapperClassName,
  crownAreaWrapperClassName,
  toothWrapperClassName,
  crownPartClassName,
  toothImageChildren,
  firstFinding = {},
  primary,
  insideOverlay,
  permanent,
  secondaryTooth,
}) {
  const [
    crownAreaFindings,
    setCrownAreaFindings,
  ] = useState([]);
  const {
    setActiveTeeth,
    activeTeeth,
    canEditTreatment,
    indicatorColors,
  } = useTeethStore();
  const isActiveTooth =
    activeTeeth.includes(tooth);
  const crownAreaCoords =
    toothLargeviewProps[tooth]?.coords;
  // const treatmentsByEoProcStatus = findTreatmentsByEoProcStatus(visibleFindings);
  // const findingsToTravers = treatmentsByEoProcStatus?.length ? [...treatmentsByEoProcStatus] : visibleFindings;
  // const findingData = findingsToTravers?.find((finding) => tooth === usToothMapping[finding?.ToothNum]);
  const procStatusData =
    indicatorColors[firstFinding?.ProcStatus];

  new ImageMap(
    `img[usemap="#crownUpperViewMapImage${insideOverlay}${tooth}"]`,
    200
  );

  const toothNumbersToHide = [
    18, 17, 16, 26, 27, 28, 46, 47, 48, 37, 38,
    36,
  ];

  useEffect(() => {
    const surfAreas = [];

    if (!hasProcedureDeleted(firstFinding)) {
      const inlineSurfaces = { M: "D", D: "M" };

      firstFinding?.Surf?.split("")?.forEach(
        (splitedSurf) => {
          if (splitedSurf !== "V") {
            let area = "";

            if (
              upperJawTeeth.includes(tooth) ||
              upperJawOverlayTeeth.includes(tooth)
            ) {
              if (
                tooth > 18 ||
                upperJawRightOverlayTeeth.includes(
                  tooth
                )
              ) {
                area =
                  readableUpperRightCrowAreas[
                    inlineSurfaces[splitedSurf] ||
                      splitedSurf
                  ];
              } else {
                area =
                  readableUpperLeftCrowAreas[
                    splitedSurf
                  ];
              }
            } else {
              if (
                tooth > 38 ||
                lowerJawLeftOverlayTeeth.includes(
                  tooth
                )
              ) {
                area =
                  readableLowerRightCrowAreas[
                    inlineSurfaces[splitedSurf] ||
                      splitedSurf
                  ];
              } else {
                area =
                  readableLowerLeftCrowAreas[
                    splitedSurf
                  ];
              }
            }

            surfAreas.push({
              area,
              findingPath: firstFinding?.ProcCode,
            });
          }
        }
      );
    }

    setCrownAreaFindings(surfAreas);
  }, [firstFinding]);

  const handleToothClick = (
    _e,
    secondaryToothClicked = false
  ) => {
    console.log(secondaryToothClicked);
    if (canEditTreatment) {
      const activeTeethData = [...activeTeeth];
      if (
        secondaryToothClicked &&
        secondaryTooth &&
        primary
      ) {
        const toothIndex =
          activeTeethData.findIndex(
            (activeTooth) =>
              secondaryTooth === activeTooth
          );
        if (toothIndex !== -1) {
          activeTeethData.splice(toothIndex, 1);
        } else {
          activeTeethData.push(secondaryTooth);
        }
      } else {
        const toothIndex =
          activeTeethData.findIndex(
            (activeTooth) => tooth === activeTooth
          );
        if (toothIndex !== -1) {
          activeTeethData.splice(toothIndex, 1);
        } else {
          activeTeethData.push(tooth);
        }
      }
      console.log(activeTeethData);
      setActiveTeeth(activeTeethData);
    }
  };

  const clearFillColor = () => {
    const canvas = document.getElementById(
      `selectedCrownAreaImageCanvas${insideOverlay}${tooth}`
    );
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, 93, 170);
  };

  const fillColorInCanvas = (coord, color) => {
    if (coord && color) {
      const coords = coord.split(",");

      const canvas = document.getElementById(
        `selectedCrownAreaImageCanvas${insideOverlay}${tooth}`
      );
      const ctx = canvas.getContext("2d");

      ctx.fillStyle = `${color}`;

      ctx.beginPath();

      if (coords.length === 3) {
        ctx.arc(
          coords[0],
          coords[1],
          coords[2],
          0,
          2 * Math.PI,
          false
        );
      } else {
        ctx.moveTo(coords[0], coords[1]);

        for (
          let item = 2;
          item < coords.length - 1;
          item += 2
        ) {
          ctx.lineTo(
            coords[item],
            coords[item + 1]
          );
        }
      }

      ctx.closePath();
      ctx.fill();
    }
  };

  const fillCrownArea = () => {
    clearFillColor();

    if (crownAreaFindings?.length) {
      const center = document
        .getElementById(`map-center-${insideOverlay}${tooth}`)
        ?.getAttribute("coords");

      const left = document
        .getElementById(`map-left-${insideOverlay}${tooth}`)
        ?.getAttribute("coords");
      const top = document
        .getElementById(`map-top-${insideOverlay}${tooth}`)
        ?.getAttribute("coords");
      const bottom = document
        .getElementById(`map-bottom-${insideOverlay}${tooth}`)
        ?.getAttribute("coords");
      const right = document
        .getElementById(`map-right-${insideOverlay}${tooth}`)
        ?.getAttribute("coords");

      const coords = {
        center,
        right,
        left,
        top,
        bottom,
      };

      crownAreaFindings.forEach((finding) => {
        fillColorInCanvas(
          coords[finding?.area],
          findingAvailableMaterials[
            finding?.findingPath
          ]?.crownPartColor
        );
      });
    }
  };

  const handleCrownImageLoad = () => {
    const container = document.getElementById(
      `crownUpperviewContainer${insideOverlay}${tooth}`
    );
    const { width, height } =
      container.getBoundingClientRect();

    const selectedCanvas =
      document.getElementById(
        `selectedCrownAreaImageCanvas${insideOverlay}${tooth}`
      );

    selectedCanvas.height = height;
    selectedCanvas.width = width;

    fillCrownArea();
  };

  useEffect(() => {
    fillCrownArea();
  }, [crownAreaCoords, crownAreaFindings, tooth]);

  useEffect(() => {
    document.addEventListener(
      "resize",
      fillCrownArea
    );

    return () =>
      document.removeEventListener(
        "resize",
        fillCrownArea
      );
  });

  const renderToothImage = useMemo(
    () => (
      <Box
        className={toothImageWrapperClassName}
        position="relative"
      >
        {toothImage && (
          <StyledImage
            right={right}
            src={toothImage}
            alt={`Tooth ${tooth}`}
          />
        )}

        {crownPartImage && (
          <Box
            className={crownPartClassName || ""}
            sx={{
              position: "absolute",
              bottom: lowerJaw ? "unset" : 0,
              top: lowerJaw ? 0 : "unset",
            }}
          >
            <StyledImage
              right={right}
              alt={`Crown part ${tooth}`}
              src={crownPartImage}
            />
          </Box>
        )}

        {toothImageChildren && toothImageChildren}
      </Box>
    ),
    [
      right,
      tooth,
      toothImage,
      toothImageWrapperClassName,
      crownPartImage,
      lowerJaw,
      crownPartClassName,
    ]
  );
  const renderCrownAreaImage = useMemo(
    () => (
      <Box
        className={crownAreaWrapperClassName}
        position="relative"
        id={`crownUpperviewContainer${insideOverlay}${tooth}`}
      >
        <StyledImage
          right={right}
          src={crownAreaImage}
          alt={`Crown area ${tooth}`}
          useMap={`#crownUpperViewMapImage${insideOverlay}${tooth}`}
          onLoad={handleCrownImageLoad}
        />

        <StyledCanvas
          id={`selectedCrownAreaImageCanvas${insideOverlay}${tooth}`}
          right={right}
        />

        <map
          name={`crownUpperViewMapImage${insideOverlay}${tooth}`}
          id={`crownUpperViewMapImage${insideOverlay}${tooth}`}
        >
          {/* Center */}
          <StyledArea
            alt=""
            id={`map-center-${insideOverlay}${tooth}`}
            title=""
            coords={crownAreaCoords?.center}
            shape="center"
            right={right}
          />
          {/* Bottom */}
          <StyledArea
            alt=""
            title=""
            id={`map-bottom-${insideOverlay}${tooth}`}
            coords={crownAreaCoords?.bottom}
            shape="poly"
            right={right}
          />
          {/* Right */}
          <StyledArea
            alt=""
            title=""
            id={`map-right-${insideOverlay}${tooth}`}
            coords={crownAreaCoords?.right}
            shape="poly"
            right={right}
          />
          {/* Top */}
          <StyledArea
            alt=""
            title=""
            id={`map-top-${insideOverlay}${tooth}`}
            coords={crownAreaCoords?.top}
            shape="poly"
            right={right}
          />
          {/* Left */}
          <StyledArea
            alt=""
            title=""
            id={`map-left-${insideOverlay}${tooth}`}
            coords={crownAreaCoords?.left}
            shape="poly"
            right={right}
          />
        </map>
      </Box>
    ),
    [
      crownAreaCoords?.bottom,
      crownAreaCoords?.center,
      crownAreaCoords?.left,
      crownAreaCoords?.right,
      crownAreaCoords?.top,
      crownAreaImage,
      crownAreaWrapperClassName,
      right,
      tooth,
      crownAreaFindings,
    ]
  );

  const getToothStyleForPrimaryCase = () => {
    // Primary and Up side
    if (primary && !insideOverlay) {
      // hide 18, 17, 16, 26, 27, 28, 46, 47, 48, 37, 38, 36, when primary and up
      if (toothNumbersToHide.includes(tooth)) {
        return { visibility: "hidden" };
      }
      return { transform: "scale(0.5)" };
    } else if (primary && insideOverlay) { // Primary and Down side
      return {};
    } else if (!primary && insideOverlay) { // Permanent and Down side
      return { visibility: "hidden" };
    } else if (!primary && !insideOverlay) { // Permanent and Up side
      return {};
    } 
  };

  const isToothHidden = (primary && !insideOverlay && toothNumbersToHide.includes(tooth)) || (!primary && insideOverlay)


  const renderUpperJaw = useMemo(
    () => (
      <>
        <Box
          sx={getToothStyleForPrimaryCase()}
          onClick={handleToothClick}
        >
          {renderToothImage}
          {renderCrownAreaImage}
        </Box>

        {secondaryTooth && primary && (
          <ToothNumber
            onClick={(e) => {
              e.preventDefault();
              handleToothClick(e, true);
            }}
            bgColor={
              activeTeeth.includes(secondaryTooth)
                ? "white"
                : procStatusData?.color
            }
            textColor={
              !insideOverlay &&
              !activeTeeth.includes(
                secondaryTooth
              )
                ? "white"
                : "black"
            }
            mixDentition={true}
            sx={
              (permanent && insideOverlay) ||
              ([
                16, 17, 18, 26, 27, 28, 46, 47,
                48, 36, 37, 38,
              ].includes(secondaryTooth) &&
                insideOverlay)
                ? { visibility: "hidden" }
                : {}
            }
          >
            {getToothDisplayNumber(
              secondaryTooth
            )}
          </ToothNumber>
        )}
        {!insideOverlay ? (
          <ToothNumber
            onClick={(e) => {
              e.preventDefault();
              handleToothClick(e, false);
            }}
            bgColor={
              activeTeeth.includes(tooth)
                ? "white"
                : procStatusData?.color
            }
            textColor={
              !insideOverlay &&
              !activeTeeth.includes(tooth)
                ? "white"
                : "bliack"
            }
            mixDentition={true}
            sx={
              (permanent && insideOverlay) ||
              ([
                16, 17, 18, 26, 27, 28, 46, 47,
                48, 36, 37, 38,
              ].includes(tooth) &&
                insideOverlay)
                ? { visibility: "hidden" }
                : {}
            }
          >
            {getToothDisplayNumber(tooth)}
          </ToothNumber>
        ) : (
          <div style={{ height: "24px" }}></div>
        )}
      </>
    ),
    [
      renderCrownAreaImage,
      renderToothImage,
      tooth,
      procStatusData,
      insideOverlay,
    ]
  );

  const renderLowerJaw = useMemo(
    () => (
      <>
        {!insideOverlay ? (
          <ToothNumber
            onClick={(e) => {
              e.preventDefault();
              handleToothClick(e, false);
            }}
            bgColor={
              activeTeeth.includes(tooth)
                ? "white"
                : procStatusData?.color
            }
            textColor={
              !insideOverlay &&
              !activeTeeth.includes(tooth)
                ? "white"
                : "black"
            }
            mixDentition={true}
            sx={
              (permanent && insideOverlay) ||
              ([
                16, 17, 18, 26, 27, 28, 46, 47,
                48, 36, 37, 38,
              ].includes(tooth) &&
                insideOverlay)
                ? { visibility: "hidden" }
                : {}
            }
          >
            {getToothDisplayNumber(tooth)}
          </ToothNumber>
        ) : (
          <div style={{ height: "24px" }}></div>
        )}
        {secondaryTooth && primary && (
          <ToothNumber
            onClick={(e) => {
              e.preventDefault();
              handleToothClick(e, true);
            }}
            bgColor={
              activeTeeth.includes(secondaryTooth)
                ? "white"
                : procStatusData?.color
            }
            textColor={
              !insideOverlay &&
              !activeTeeth.includes(
                secondaryTooth
              )
                ? "white"
                : 'black'
            }
            mixDentition={true}
            sx={
              (permanent && insideOverlay) ||
              ([
                16, 17, 18, 26, 27, 28, 46, 47,
                48, 36, 37, 38,
              ].includes(secondaryTooth) &&
                insideOverlay)
                ? { visibility: "hidden" }
                : {}
            }
          >
            {getToothDisplayNumber(
              secondaryTooth
            )}
          </ToothNumber>
        )}
        <Box
          sx={getToothStyleForPrimaryCase()}
          onClick={handleToothClick}
        >
          {renderCrownAreaImage}
          {renderToothImage}
        </Box>
      </>
    ),
    [
      renderCrownAreaImage,
      renderToothImage,
      tooth,
      procStatusData,
      insideOverlay,
    ]
  );

  return (
    <StyledToothWrapper
      className={toothWrapperClassName}
      insideOverlay={insideOverlay}
      isToothHidden={isToothHidden}
      justifyContent={
        lowerJaw ? "flex-start" : "flex-end"
      }
      // onClick={handleToothClick}
      isActiveTooth={isActiveTooth}
      lowerJaw={lowerJaw}
    >
      {lowerJaw ? renderLowerJaw : renderUpperJaw}
    </StyledToothWrapper>
  );
}

import React from 'react';
import { YAxis, CartesianGrid, ResponsiveContainer, Line, ComposedChart, Bar, XAxis } from 'recharts';

export const LineGraph = ({ data, reversed, xAxisPadding }) => {
  return (
    <ResponsiveContainer
      width='100%'
      height='100%'
    >
      <ComposedChart
        width={500}
        height={400}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        data={data}
        connectNulls={true}
        // barSize={1}
        // barCategoryGap={20}
        // barGap={1}
        // barCategoryGap='60%'
      >
        <CartesianGrid
          strokeDasharray='1 0'
          // spacing={0}
          horizontalPoints={['100%', '66%', '33%', 0]}
          accentHeight={1}
          vertical={false}
          strokeWidth={1}
        />
        {/* <XAxis dataKey="name" hide/> */}
        <YAxis
          domain={[0, 9]}
          tickCount={1}
          type='number'
          hide
          reversed={reversed}
        />
        {/* <Tooltip /> */}
        <Line
          dataKey='value'
          stroke='#000'
          dot={false}
        />
        <Bar
          layout='vertical'
          // xAxis={false}
          // yAxis={false}
          dataKey='value'
          barSize={1}
          // maxBarSize={1}
          fill='#413ea0'
        />
        <XAxis
          hide={true}
          scale='number'
          padding={xAxisPadding}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

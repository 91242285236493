import React from "react";
import NotFoundPage from "../../components/NotFound";
import { useSearchParams } from "react-router-dom";
import { apiErrorMessage } from "../../constants/apiErrorMessages";

export const Forbidden403 = () => {
  const [searchParams] = useSearchParams();

  const apiStatus = searchParams.get("status");

  return (
    <NotFoundPage
      title="Internal Error!!"
      subTitle="Looks like you accessed application with invalid data"
      message={apiErrorMessage[apiStatus] || "Something went wrong."}
    />
  );
};

import dayjs from 'dayjs';
import { findingAvailableMaterials, findingOptions } from '../../constants/findings/findingOptions';
import {
  biologicalLowerLeftCrowAreas,
  biologicalLowerRightCrowAreas,
  biologicalUpperLeftCrowAreas,
  biologicalUpperRightCrowAreas,
} from '../../constants/teeth/areas';
import {  upperJawTeeth } from '../../constants/teeth/tooth';
import { useTeethStore } from '../../zustand/teethStore';

export const hasProcedureDeleted = (finding) =>
  useTeethStore.getState()?.indicatorColors?.[finding?.ProcStatus]?.title === 'D';

export const hasMissingTooth = (findings = []) => findings?.some((finding) => finding?.Missing);

export const getCrownAreaPartName = (readableArea, toothNumber) => {
  const inlineSurfaces = { left: 'right', right: 'left' };
  if (upperJawTeeth.includes(toothNumber)) {
    if (toothNumber > 18) {
      return biologicalUpperRightCrowAreas[inlineSurfaces[readableArea] || readableArea];
    }
    return biologicalUpperLeftCrowAreas[readableArea];
  } else {
    if (toothNumber > 38) {
      return biologicalLowerRightCrowAreas[inlineSurfaces[readableArea] || readableArea];
    }
    return biologicalLowerLeftCrowAreas[readableArea];
  }
};

export const getStylesByTooth = (tooth, finding, missing) => {
  const { crownColor, rootColor } = missing
    ? { crownColor: '', rootColor: '' }
    : finding?.reduce(
      (acc, curr) => {
        if ((curr?.area === 'tooth' || !curr?.Surf) && !hasProcedureDeleted(curr)) {
          acc.crownColor = `${acc.crownColor} ${findingOptions[curr?.findingPath || curr?.ProcCode]?.crownColor ||
            findingAvailableMaterials[curr?.findingPath || curr?.ProcCode]?.crownColor
            }`;
          acc.rootColor = `${acc.rootColor} ${findingOptions[curr?.findingPath || curr?.ProcCode]?.rootColor ||
            findingAvailableMaterials[curr?.findingPath || curr?.ProcCode]?.rootColor
            }`;

          return acc;
        }

        return acc;
      },
      {
        crownColor: '',
        rootColor: '',
      }
    );

  return {
    natural: {
      toothWrapper: `tooth-container-${tooth}`,
      toothImageWrapper: `tooth-${tooth} ${rootColor}`,
      toothCrownPart: `${crownColor}`,
      crownAreaImageWrapper: `crown-area-${tooth} ${crownColor}`,
    },
    implant: {
      toothWrapper: `tooth-container-implant-${tooth}`,
      toothImageWrapper: `tooth-implant-${tooth}`,
      toothCrownPart: `tooth-implant-crownPart-${tooth} ${crownColor}`,
      crownAreaImageWrapper: `crown-area-${tooth} ${crownColor}`,
    },
    babyNatural: {
      toothWrapper: `tooth-container-baby-${tooth}`,
      toothImageWrapper: `tooth-baby-${tooth}`,
    },
    babyToothMissing: {
      toothWrapper: `tooth-container-babyMissing-${tooth}`,
      toothImageWrapper: `tooth-babyMissing-${tooth}`,
    },
    notYetDeveloped: {
      toothImageWrapper: `tooth-${tooth} tooth-notYetDeveloped tooth-notYetDeveloped-${tooth}`,
      crownAreaImageWrapper: `crown-area-${tooth} crown-area-notYetDeveloped crown-area-notYetDeveloped-${tooth}`,
    },
    veneer: {
      toothWrapper: `tooth-container-${tooth}`,
      toothImageWrapper: `tooth-${tooth}`,
      toothCrownPart: `${crownColor}`,
      crownAreaImageWrapper: `crown-area-${tooth} ${crownColor}`,
    },
    pontics: {
      toothWrapper: `tooth-container-${tooth}`,
      toothImageWrapper: `tooth-${tooth} ${rootColor}`,
      toothCrownPart: `${crownColor}`,
      crownAreaImageWrapper: `crown-area-${tooth} ${crownColor}`,
    },
    missing: {
      toothWrapper: `tooth-container-${tooth}`,
      toothImageWrapper: `tooth-${tooth} ${rootColor} hide`,
      toothCrownPart: `${crownColor}`,
      crownAreaImageWrapper: `crown-area-${tooth} ${crownColor} hide`,
    },
  };
};

export const findTreatmentsByEoProcStatus = (findings = []) => {
  const filteredFindings = [...findings]?.filter(
    (finding) => finding?.ProcStatus === useTeethStore.getState()?.indicatorColors?.[4].code
  );

  if (!filteredFindings.length) {
    return null;
  }

  filteredFindings.sort((first, second) => second?.DateTStamp?.localeCompare(first?.DateTStamp));

  return filteredFindings;
};

export const hasPrimaryTeethChartActive = () => useTeethStore.getState()?.activeTeethChartTab === 1;
export const hasMixDentitionTeethChartActive = () => useTeethStore.getState()?.activeTeethChartTab === 2;

export const filterDeletedTreatments = (findings = []) => {
  let filterFindingsBasedOnOverlay = [];
    filterFindingsBasedOnOverlay = [...findings];

  const filteredFindings = [...filterFindingsBasedOnOverlay]?.filter(
    (finding) => finding?.ProcStatus !== useTeethStore.getState()?.indicatorColors?.[6].code
  );

  filteredFindings.sort((first, second) => second?.DateTStamp?.localeCompare(first?.DateTStamp));

  return filteredFindings;
};

export const getToothAndCrownTypeByFinding = (findings) => {
  const toothFinding = findings?.filter((finding) => finding?.area === 'tooth' || !finding?.Surf)[0] || {};
  const toothType =
    findingAvailableMaterials[toothFinding?.findingPath || toothFinding?.ProcCode]?.toothType || toothFinding?.finding;
  const crownType =
    findingAvailableMaterials[toothFinding?.findingPath || toothFinding?.ProcCode]?.crownType || toothFinding?.finding;

  return { toothType, crownType };
};

export const toothDisplayNumberMap = {
  18: 1,
  17: 2,
  16: 3,
  15: 4,
  14: 5,
  13: 6,
  12: 7,
  11: 8,
  21: 9,
  22: 10,
  23: 11,
  24: 12,
  25: 13,
  26: 14,
  27: 15,
  28: 16,
  48: 32,
  47: 31,
  46: 30,
  45: 29,
  44: 28,
  43: 27,
  42: 26,
  41: 25,
  31: 24,
  32: 23,
  33: 22,
  34: 21,
  35: 20,
  36: 19,
  37: 18,
  38: 17,
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
};

export const primaryToothMap = {
  A: 15,
  B: 14,
  C: 13,
  D: 12,
  E: 11,
  F: 21,
  G: 22,
  H: 23,
  I: 24,
  J: 25,
  K: 35,
  L: 34,
  M: 33,
  N: 32,
  O: 31,
  P: 41,
  Q: 42,
  R: 43,
  S: 44,
  T: 45,
}

export const getToothDisplayNumber = (fileToothNumber) => toothDisplayNumberMap[fileToothNumber];

export const createProcDate = (date) => `${dayjs(date).format('YYYY-MM-DD')}T00:00:00`;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from './routes';
import { SnackbarProvider } from 'notistack';

const root = ReactDOM.createRoot(document.getElementById('root'));
const routing = createBrowserRouter(routes());

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <RouterProvider router={routing} />
    </SnackbarProvider>
  </React.StrictMode>
);

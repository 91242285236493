import React from "react";
import NotFoundPage from "../../components/NotFound";

export const NotFound404 = () => {
  return (
    <NotFoundPage
      title="Not Found!!"
      subTitle="Looks like you got lost"
      message="Page you're trying to access is either deleted or never existed."
    />
  );
};

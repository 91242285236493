import { Box, Slider, Stack, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Mouth from "../Mouth";
import { useTeethStore } from "../../../zustand/teethStore";
import dayjs from "dayjs";
import { createProcDate } from "../helper";
import { TeethChartsTab } from "../common/TeethChartsTab";

export const View = () => {
  const [findingDateIndex, setFindingDateIndex] = useState(0);

  const {
    allFindings,
    setFirstProcDate,
    setActiveDateFindings,
    setVisibleFindings,
    indicatorColors,
    visibleFindings,
  } = useTeethStore();
  const findingDateMarks = useMemo(
    () =>
      allFindings
        ?.filter(
          (finding, findingIndex, localFindings) =>
            localFindings?.findIndex(
              (localFinding) => localFinding?.ProcDate === finding?.ProcDate,
            ) === findingIndex,
        )
        ?.sort((first, second) =>
          first.ProcDate?.localeCompare(second.ProcDate),
        )
        ?.map((finding, findingIndex) => ({
          value: findingIndex,
          label: dayjs(finding?.ProcDate).format("MM/DD/YYYY"),
          ProcDate: finding?.ProcDate,
        })),

    [allFindings],
  );

  const findingMarksToShow = useMemo(
    () => [
      ...findingDateMarks,
      {
        value: findingDateMarks.length,
        label: "Current Date",
        ProcDate: "current",
      },
    ],
    [findingDateMarks],
  );

  const sliderMarkLableStyle = useCallback(() => {
    const slider = document.getElementById("treatmentDateSlider");

    if (slider) {
      const { width } = slider.getBoundingClientRect();
      const itemsWidth = findingMarksToShow.length * 76;

      if (itemsWidth > width) {
        return {
          transform: "translateX(-50%) rotate(-55deg)",
          top: "50px",
        };
      }

      return {};
    }
  }, [findingMarksToShow]);

  useEffect(() => {
    setFirstProcDate(
      findingMarksToShow.length > 1
        ? findingMarksToShow?.[0]?.ProcDate || new Date()
        : new Date(),
    );
  }, [findingMarksToShow, setFirstProcDate]);

  const setFindingsForSelectedDate = useCallback(
    (selectedDate) => {
      if (selectedDate === "current") {
        const sortedFindings = [...allFindings].sort((first, second) =>
          second?.DateTStamp?.localeCompare(first?.DateTStamp),
        );
        setActiveDateFindings(sortedFindings);
        setVisibleFindings(sortedFindings);
      } else {
        const sortedFindings = [...allFindings]
          ?.filter(
            (finding) =>
              finding.ProcDate === createProcDate(selectedDate) ||
              finding?.Missing ||
              finding?.ProcStatus === indicatorColors[4].code,
          )
          ?.sort((first, second) =>
            second?.DateTStamp?.localeCompare(first?.DateTStamp),
          );
        setActiveDateFindings(sortedFindings);
        setVisibleFindings(sortedFindings);
      }
    },
    [allFindings, indicatorColors, setActiveDateFindings, setVisibleFindings],
  );

  useEffect(() => {
    setFindingDateIndex(
      findingMarksToShow.length > 1 ? findingMarksToShow.length - 2 : 0,
    );
    setFindingsForSelectedDate(
      findingMarksToShow[findingMarksToShow?.length - 2]?.ProcDate,
    );
  }, [findingMarksToShow, setFindingsForSelectedDate]);

  const handleFindingDateChange = (_event, dateIndex) => {
    setFindingDateIndex(dateIndex);
    setFindingsForSelectedDate(findingMarksToShow[dateIndex]?.ProcDate);
  };

  return (
    <>
      {/* <TeethChartsTab /> */}

      <Stack
        direction="row"
        // visibility={!!allFindings.length ? 'visible' : 'hidden'}
        overflow="auto"
      >
        <Mouth />
      </Stack>

      {!!allFindings.length && (
        <>
          <Box
            mb={5}
            mx={{ xs: "unset", md: "auto" }}
            paddingX="40px"
            maxWidth="100%"
            overflow="auto"
          >
            <Typography gutterBottom>Treatment Date</Typography>
            <Slider
              id="treatmentDateSlider"
              aria-label="Treatment date"
              valueLabelDisplay="auto"
              step={null}
              marks={findingMarksToShow}
              value={findingDateIndex}
              min={0}
              max={findingMarksToShow.length - 1}
              onChange={handleFindingDateChange}
              track={false}
              valueLabelFormat={() =>
                findingMarksToShow[findingDateIndex]?.ProcDate === "current"
                  ? "Current date"
                  : dayjs(
                      findingMarksToShow[findingDateIndex]?.ProcDate,
                    ).format("MM/DD/YYYY")
              }
              sx={{
                mb: 10,
                ".MuiSlider-mark": { height: "5px", width: "5px" },
                ".MuiSlider-markLabel": {
                  color: "#bdbdbd",
                  zIndex: 999,
                  ...sliderMarkLableStyle(),
                },
                ".MuiSlider-markLabelActive": {
                  color: "#616161",
                },
              }}
            />
          </Box>
        </>
      )}
    </>
  );
};

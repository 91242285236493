/* eslint-disable eqeqeq */
import { Box, Divider, Stack } from "@mui/material";
import React, { useCallback } from "react";
import Tooth18 from "./tooth/Tooth18";
import Tooth17 from "./tooth/Tooth17";
import Tooth16 from "./tooth/Tooth16";
import Tooth15 from "./tooth/Tooth15";
import Tooth14 from "./tooth/Tooth14";
import Tooth13 from "./tooth/Tooth13";
import Tooth12 from "./tooth/Tooth12";
import Tooth11 from "./tooth/Tooth11";
import Tooth21 from "./tooth/Tooth21";
import Tooth22 from "./tooth/Tooth22";
import Tooth23 from "./tooth/Tooth23";
import Tooth24 from "./tooth/Tooth24";
import Tooth25 from "./tooth/Tooth25";
import Tooth26 from "./tooth/Tooth26";
import Tooth27 from "./tooth/Tooth27";
import Tooth28 from "./tooth/Tooth28";
import Tooth48 from "./tooth/Tooth48";
import Tooth47 from "./tooth/Tooth47";
import Tooth46 from "./tooth/Tooth46";
import Tooth45 from "./tooth/Tooth45";
import Tooth44 from "./tooth/Tooth44";
import Tooth43 from "./tooth/Tooth43";
import Tooth42 from "./tooth/Tooth42";
import Tooth41 from "./tooth/Tooth41";
import Tooth38 from "./tooth/Tooth38";
import Tooth37 from "./tooth/Tooth37";
import Tooth36 from "./tooth/Tooth36";
import Tooth35 from "./tooth/Tooth35";
import Tooth34 from "./tooth/Tooth34";
import Tooth33 from "./tooth/Tooth33";
import Tooth32 from "./tooth/Tooth32";
import Tooth31 from "./tooth/Tooth31";
import { useTeethStore } from "../../zustand/teethStore";
import MixDentationMouth from "./MixDentationMouth";
import { toothDisplayNumberMap } from "./helper";

export default function Mouth() {
  const { visibleFindings, primaryTeeth, permanentTeeth } = useTeethStore();

  const getFindingByTooth = useCallback(
    (tooth) =>
      visibleFindings.filter(
        (finding) => finding?.ToothNum == toothDisplayNumberMap[tooth],
      ) || "natural",
    [visibleFindings],
  );

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        minWidth: "100%",
        margin: "auto",
        overflow: "auto",
      }}
    >
      <MixDentationMouth />

      {/* Upper side tooth diagram */}
      <Stack
        className="NotMixDent"
        direction="row"
        justifyContent="center"
        gap={1.5}
        sx={{
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        <Tooth18
          overlayTooth
          finding={getFindingByTooth(18)}
          primary={primaryTeeth?.includes(18)}
          permanent={permanentTeeth?.includes(18)}
        />
        <Tooth17
          overlayTooth
          finding={getFindingByTooth(17)}
          primary={primaryTeeth?.includes(17)}
          permanent={permanentTeeth?.includes(17)}
        />
        <Tooth16
          overlayTooth
          finding={getFindingByTooth(16)}
          primary={primaryTeeth?.includes(16)}
          permanent={permanentTeeth?.includes(16)}
        />
        <Tooth15
          toothNumber={primaryTeeth?.includes(15) && "A"}
          secondaryTooth={15}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(15) ? "A" : 15)}
          primary={primaryTeeth?.includes(15)}
          permanent={permanentTeeth?.includes(15)}
        />
        <Tooth14
          toothNumber={primaryTeeth?.includes(14) && "B"}
          secondaryTooth={14}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(14) ? "B" : 14)}
          primary={primaryTeeth?.includes(14)}
          permanent={permanentTeeth?.includes(14)}
        />
        <Tooth13
          toothNumber={primaryTeeth?.includes(13) && "C"}
          secondaryTooth={13}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(13) ? "C" : 13)}
          primary={primaryTeeth?.includes(13)}
          permanent={permanentTeeth?.includes(13)}
        />
        <Tooth12
          toothNumber={primaryTeeth?.includes(12) && "D"}
          secondaryTooth={12}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(12) ? "D" : 12)}
          primary={primaryTeeth?.includes(12)}
          permanent={permanentTeeth?.includes(12)}
        />
        <Tooth11
          toothNumber={primaryTeeth?.includes(11) && "E"}
          secondaryTooth={11}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(11) ? "E" : 11)}
          primary={primaryTeeth?.includes(11)}
          permanent={permanentTeeth?.includes(11)}
        />
        <Tooth21
          toothNumber={primaryTeeth?.includes(21) && "F"}
          secondaryTooth={21}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(21) ? "F" : 21)}
          primary={primaryTeeth?.includes(21)}
          permanent={permanentTeeth?.includes(21)}
        />
        <Tooth22
          toothNumber={primaryTeeth?.includes(22) && "G"}
          secondaryTooth={22}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(22) ? "G" : 22)}
          primary={primaryTeeth?.includes(22)}
          permanent={permanentTeeth?.includes(22)}
        />
        <Tooth23
          toothNumber={primaryTeeth?.includes(23) && "H"}
          secondaryTooth={23}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(23) ? "H" : 23)}
          primary={primaryTeeth?.includes(23)}
          permanent={permanentTeeth?.includes(23)}
        />
        <Tooth24
          toothNumber={primaryTeeth?.includes(24) && "I"}
          secondaryTooth={24}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(24) ? "I" : 24)}
          primary={primaryTeeth?.includes(24)}
          permanent={permanentTeeth?.includes(24)}
        />
        <Tooth25
          toothNumber={primaryTeeth?.includes(25) && "J"}
          secondaryTooth={25}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(25) ? "J" : 25)}
          primary={primaryTeeth?.includes(25)}
          permanent={permanentTeeth?.includes(25)}
        />
        <Tooth26
          overlayTooth
          finding={getFindingByTooth(26)}
          primary={primaryTeeth?.includes(26)}
          permanent={permanentTeeth?.includes(26)}
        />
        <Tooth27
          overlayTooth
          finding={getFindingByTooth(27)}
          primary={primaryTeeth?.includes(27)}
          permanent={permanentTeeth?.includes(27)}
        />
        <Tooth28
          overlayTooth
          finding={getFindingByTooth(28)}
          primary={primaryTeeth?.includes(28)}
          permanent={permanentTeeth?.includes(28)}
        />
      </Stack>

      <Divider sx={{ my: 0 }} />

      <Stack
        direction="row"
        justifyContent="center"
        gap={1.5}
        pb={{ xs: 3, md: 8 }}
        sx={{
          backgroundColor: "rgba(0,0,0,0.3)",
        }}
      >
        <Tooth48
          overlayTooth
          finding={getFindingByTooth(48)}
          primary={primaryTeeth?.includes(48)}
          permanent={permanentTeeth?.includes(48)}
        />
        <Tooth47
          overlayTooth
          finding={getFindingByTooth(47)}
          primary={primaryTeeth?.includes(47)}
          permanent={permanentTeeth?.includes(47)}
        />
        <Tooth46
          overlayTooth
          finding={getFindingByTooth(46)}
          primary={primaryTeeth?.includes(46)}
          permanent={permanentTeeth?.includes(46)}
        />
        <Tooth45
          toothNumber={primaryTeeth?.includes(45) && "T"}
          secondaryTooth={45}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(45) ? "T" : 45)}
          primary={primaryTeeth?.includes(45)}
          permanent={permanentTeeth?.includes(45)}
        />
        <Tooth44
          toothNumber={primaryTeeth?.includes(44) && "S"}
          secondaryTooth={44}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(44) ? "S" : 44)}
          primary={primaryTeeth?.includes(44)}
          permanent={permanentTeeth?.includes(44)}
        />
        <Tooth43
          toothNumber={primaryTeeth?.includes(43) && "R"}
          secondaryTooth={43}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(43) ? "R" : 43)}
          primary={primaryTeeth?.includes(43)}
          permanent={permanentTeeth?.includes(43)}
        />
        <Tooth42
          toothNumber={primaryTeeth?.includes(42) && "Q"}
          secondaryTooth={42}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(42) ? "Q" : 42)}
          primary={primaryTeeth?.includes(42)}
          permanent={permanentTeeth?.includes(42)}
        />
        <Tooth41
          toothNumber={primaryTeeth?.includes(41) && "P"}
          secondaryTooth={41}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(41) ? "P" : 41)}
          primary={primaryTeeth?.includes(41)}
          permanent={permanentTeeth?.includes(41)}
        />
        <Tooth31
          toothNumber={primaryTeeth?.includes(31) && "O"}
          secondaryTooth={31}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(31) ? "O" : 31)}
          primary={primaryTeeth?.includes(31)}
          permanent={permanentTeeth?.includes(31)}
        />
        <Tooth32
          toothNumber={primaryTeeth?.includes(32) && "N"}
          secondaryTooth={32}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(32) ? "N" : 32)}
          primary={primaryTeeth?.includes(32)}
          permanent={permanentTeeth?.includes(32)}
        />
        <Tooth33
          toothNumber={primaryTeeth?.includes(33) && "M"}
          secondaryTooth={33}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(33) ? "M" : 33)}
          primary={primaryTeeth?.includes(33)}
          permanent={permanentTeeth?.includes(33)}
        />
        <Tooth34
          toothNumber={primaryTeeth?.includes(34) && "L"}
          secondaryTooth={34}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(34) ? "L" : 34)}
          primary={primaryTeeth?.includes(34)}
          permanent={permanentTeeth?.includes(34)}
        />
        <Tooth35
          toothNumber={primaryTeeth?.includes(35) && "K"}
          secondaryTooth={35}
          overlayTooth
          finding={getFindingByTooth(primaryTeeth?.includes(35) ? "K" : 35)}
          primary={primaryTeeth?.includes(35)}
          permanent={permanentTeeth?.includes(35)}
        />
        <Tooth36
          overlayTooth
          finding={getFindingByTooth(36)}
          primary={primaryTeeth?.includes(36)}
          permanent={permanentTeeth?.includes(36)}
        />
        <Tooth37
          overlayTooth
          finding={getFindingByTooth(37)}
          primary={primaryTeeth?.includes(37)}
          permanent={permanentTeeth?.includes(37)}
        />
        <Tooth38
          overlayTooth
          finding={getFindingByTooth(38)}
          primary={primaryTeeth?.includes(38)}
          permanent={permanentTeeth?.includes(38)}
        />
      </Stack>
    </Box>
  );
}

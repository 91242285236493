import { colors } from '../../components/TeethDiagram/colors';
import { overlayTeeth } from '../teeth/tooth';

const RctFindingOption = {
  name: 'rct',
  label: 'RCT',
  procCode: 'D3310',
  availablePositions: [
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    38,
    37,
    36,
    35,
    34,
    33,
    32,
    31,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    ...overlayTeeth,
  ],
  availableAreas: ['root'],
  _id: '32393201121860013078',
  availableModifiers: [],
  availableLocations: [],
  criticality: 1,
  availableMaterials: [],
};

const RctPuPpfFindingOption = {
  name: 'rctPuPpf',
  label: 'RCT PU, PPF',
  procCode: 'D2950',
  availablePositions: [
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    38,
    37,
    36,
    35,
    34,
    33,
    32,
    31,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    ...overlayTeeth,
  ],
  availableAreas: ['root'],
  _id: '3239320112186001307812',
  availableModifiers: [],
  availableLocations: [],
  criticality: 1,
  availableMaterials: [],
};

export const findingAvailableMaterials = {
  // Composite Category
  '3-0': {
    name: 'composite',
    label: 'Composite',
    procCode: 'N4118',
    rootColor: '',
    crownColor: 'sky-blue',
    toothType: 'natural',
    crownType: 'natural',
    availableMaterials: [],
    // availableMaterials: ['3-0-0', '3-0-1', '3-0-2'],
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
  },
  // '3-0-0': {
  //   name: 'singleCrown',
  //   label: 'Single Crown',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-0-0-0', '3-0-0-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-0-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-0-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-0-0-1-0', '3-0-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-0-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-0-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-0-1': {
  //   name: 'abutment',
  //   label: 'Abutment',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-0-1-0', '3-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-1-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-1-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-0-1-1-0', '3-0-1-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-1-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-1-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'skin',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-0-2': {
  //   name: 'pontic',
  //   label: 'Pontic',
  //   rootColor: 'transparent',
  //   crownColor: 'sky-blue',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-0-2-0', '3-0-2-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-2-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: 'transparent',
  //   crownColor: 'sky-blue',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-2-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-0-2-1-0', '3-0-2-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-2-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'black screw',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-0-2-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'sky-blue',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // Ceremic Category
  // '3-1': {
  //   name: 'ceremic',
  //   label: 'Ceremic',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-1-0', '3-1-1', '3-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-0': {
  //   name: 'singleCrown',
  //   label: 'Single Crown',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-1-0-0', '3-1-0-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-1-0-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-0-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-1-0-1-0', '3-1-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-0-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-0-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-1-1': {
  //   name: 'abutment',
  //   label: 'Abutment',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-1-1-0', '3-1-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-1-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-1-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-1-1-1-0', '3-1-1-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-1-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-1-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-1-2': {
  //   name: 'pontic',
  //   label: 'Pontic',
  //   rootColor: 'transparent',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-1-2-0', '3-1-2-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-2-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: 'transparent',
  //   crownColor: 'purple',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-2-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-1-2-1-0', '3-1-2-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-2-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'black screw',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-1-2-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'purple',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // // Gold Category
  // '3-2': {
  //   name: 'gold',
  //   label: 'Gold',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-2-0', '3-2-1', '3-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-0': {
  //   name: 'singleCrown',
  //   label: 'Single Crown',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-2-0-0', '3-2-0-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-0-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-0-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-2-0-1-0', '3-2-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-0-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-0-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-2-1': {
  //   name: 'abutment',
  //   label: 'Abutment',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-2-1-0', '3-2-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-1-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-1-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-2-1-1-0', '3-2-1-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-1-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-1-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-2-2': {
  //   name: 'pontic',
  //   label: 'Pontic',
  //   rootColor: 'transparent',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-2-2-0', '3-2-2-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-2-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: 'transparent',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-2-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-2-2-1-0', '3-2-2-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-2-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'black screw',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-2-2-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'yellow',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // // Non-Pricious Metal Category
  // '3-3': {
  //   name: 'nonPriciousMetal ',
  //   label: 'Non-Pricious Metal ',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-3-0', '3-3-1', '3-3-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-0': {
  //   name: 'singleCrown',
  //   label: 'Single Crown',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-3-0-0', '3-3-0-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-0-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'yellow',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-0-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-3-0-1-0', '3-3-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-0-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-0-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-3-1': {
  //   name: 'abutment',
  //   label: 'Abutment',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-3-1-0', '3-3-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-1-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-1-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-0-0-1-0', '3-0-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-1-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'skin',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-1-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '3-3-2': {
  //   name: 'pontic',
  //   label: 'Pontic',
  //   rootColor: 'transparent',
  //   crownColor: 'grey',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: ['3-3-2-0', '3-3-2-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-2-0': {
  //   name: 'natural',
  //   label: 'Natural',
  //   rootColor: 'transparent',
  //   crownColor: 'grey',
  //   toothType: 'natural',
  //   crownType: 'natural',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-2-1': {
  //   name: 'implant',
  //   label: 'Implant',
  //   rootColor: '',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: ['3-0-0-1-0', '3-0-0-1-1'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-2-1-0': {
  //   name: 'boneLevel',
  //   label: 'Bone Level',
  //   rootColor: 'black screw',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '3-3-2-1-1': {
  //   name: 'tissueLevel',
  //   label: 'Tissue Level',
  //   rootColor: 'blackScrew',
  //   crownColor: 'grey',
  //   toothType: 'implant',
  //   crownType: 'implant',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  //Filling
  // Composite Category
  '4-0': {
    name: 'composite',
    label: 'Composite',
    procCode: 'D2330',
    rootColor: '',
    crownColor: '',
    crownPartColor: colors.skyBlue,
    toothType: 'natural',
    crownType: 'filling',
    // availableMaterials: ['4-0-0', '4-0-1', '4-0-2'],
    availableMaterials: [],
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
  },
  // '4-0-0': {
  //   name: 'sufficent',
  //   label: 'Sufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-0-0-0', '4-0-0-1', '4-0-0-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-0-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-0-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-0-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-0-1': {
  //   name: 'uncertain',
  //   label: 'Uncertain',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-0-1-0', '4-0-1-1', '4-0-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-1-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-1-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-1-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-0-2': {
  //   name: 'insufficent',
  //   label: 'Insufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-0-2-0', '4-0-2-1', '4-0-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-2-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-2-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-0-2-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.skyBlue,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // // Ceremic Category
  // '4-1': {
  //   name: 'ceremic',
  //   label: 'Ceremic',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-1-0', '4-1-1', '4-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-0': {
  //   name: 'sufficent',
  //   label: 'Sufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-1-0-0', '4-1-0-1', '4-1-0-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-1-0-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-0-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-0-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-1-1': {
  //   name: 'uncertain',
  //   label: 'Uncertain',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-1-1-0', '4-1-1-1', '4-1-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-1-1-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-1-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-1-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-1-2': {
  //   name: 'insufficent',
  //   label: 'Insufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-1-2-0', '4-1-2-1', '4-1-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-2-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-2-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-1-2-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.purple,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // Amalgam Category
  '4-2': {
    name: 'amalgam',
    label: 'Amalgam',
    procCode: 'D2140',
    rootColor: '',
    crownColor: '',
    crownPartColor: colors.grey,
    toothType: 'natural',
    crownType: 'filling',
    // availableMaterials: ['4-1-0', '4-1-1', '4-1-2'],
    availableMaterials: [],
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
  },
  // '4-2-0': {
  //   name: 'sufficent',
  //   label: 'Sufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-2-0-0', '4-2-0-1', '4-2-0-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-2-0-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-0-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-0-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-2-1': {
  //   name: 'uncertain',
  //   label: 'Uncertain',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-2-1-0', '4-2-1-1', '4-2-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-2-1-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-1-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-1-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-2-2': {
  //   name: 'insufficent',
  //   label: 'Insufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-2-2-0', '4-2-2-1', '4-2-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-2-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-2-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-2-2-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.grey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // // Gold Category
  // '4-3': {
  //   name: 'gold',
  //   label: 'Gold',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-3-0', '4-3-1', '4-3-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-0': {
  //   name: 'sufficent',
  //   label: 'sufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-3-0-0', '4-3-0-1', '4-3-0-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-3-0-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-0-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-0-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-3-1': {
  //   name: 'uncertain',
  //   label: 'Uncertain',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-3-1-0', '4-3-1-1', '4-3-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-1-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-1-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-1-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-3-2': {
  //   name: 'insufficent',
  //   label: 'Insufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-3-2-0', '4-3-2-1', '4-3-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-2-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-2-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-3-2-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.yellow,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // // Non-Pricious Metal Category

  // '4-4': {
  //   name: 'nonPriciousMetal ',
  //   label: 'Non-Pricious Metal ',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-4-0', '4-4-1', '4-4-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-0': {
  //   name: 'sufficent',
  //   label: 'Sufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-4-0-0', '4-4-0-1', '4-4-0-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-0-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-0-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-0-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-1': {
  //   name: 'uncertain',
  //   label: 'Uncertain',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-4-1-0', '4-4-1-1', '4-4-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-1-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-1-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-1-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-4-2': {
  //   name: 'insufficent',
  //   label: 'Insufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-4-2-0', '4-4-2-1', '4-4-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-2-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-2-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-4-2-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.lightGrey,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // // Temporary Category

  // '4-5': {
  //   name: 'temporary ',
  //   label: 'Temporary ',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-5-0', '4-5-1', '4-5-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-0': {
  //   name: 'sufficent',
  //   label: 'Sufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-5-0-0', '4-5-0-1', '4-5-0-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-0-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-0-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-0-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-1': {
  //   name: 'uncertain',
  //   label: 'Uncertain',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-5-1-0', '4-5-1-1', '4-5-1-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-1-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-1-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-1-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },

  // '4-5-2': {
  //   name: 'insufficent',
  //   label: 'Insufficent',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: ['4-5-2-0', '4-5-2-1', '4-5-2-2'],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-2-0': {
  //   name: 'overhand',
  //   label: 'Overhand',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-2-1': {
  //   name: 'flush',
  //   label: 'Flush',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  // '4-5-2-2': {
  //   name: 'shortfall',
  //   label: 'Shortfall',
  //   rootColor: '',
  //   crownColor: '',
  //   crownPartColor: colors.cyan,
  //   toothType: 'natural',
  //   crownType: 'filling',
  //   availableMaterials: [],
  //   availablePositions: [
  //     18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
  //     36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,
  //   ],
  // },
  '7-0': {
    name: 'notYetDeveloped',
    label: 'Not yet developed',
    toothType: 'notYetDeveloped',
    crownType: 'notYetDeveloped',
    availableMaterials: [],
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
  },
  '7-1': {
    name: 'babyTooth',
    label: 'Baby tooth',
    toothType: 'babyNatural',
    crownType: 'babyNatural',
    availablePositions: [15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45],
    availableMaterials: [],
  },
  '7-2': {
    name: 'babyToothMissing',
    label: 'Baby tooth missing',
    toothType: 'babyToothMissing',
    crownType: 'babyToothMissing',
    availableMaterials: [],
    availablePositions: [15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45],
  },
  '7-3': {
    name: 'adultTooth',
    label: 'Adult tooth',
    toothType: 'natural',
    crownType: 'natural',
    availableMaterials: [],
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
  },
  '7-4': {
    name: 'adultToothMissing',
    label: 'Adult tooth missing',
    toothType: 'missing',
    crownType: 'missing',
    availableMaterials: [],
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
  },
  '5-0': { ...RctFindingOption },
  '5-1': { ...RctPuPpfFindingOption },
};

const extractionMaterial = {
  name: 'extraction',
  label: 'Extraction',
  procCode: 'D7110',
  rootColor: 'x-tooth',
  toothType: 'natural',
  crownType: 'natural',
  availableMaterials: [],
  availablePositions: [
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    38,
    37,
    36,
    35,
    34,
    33,
    32,
    31,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    ...overlayTeeth,
  ],
};

const ponticFindingOption = {
  name: 'pontics',
  label: 'Pontics',
  rootColor: 'transparent',
  procCode: 'D6545',
  crownColor: 'celeste',
  availablePositions: [
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    38,
    37,
    36,
    35,
    34,
    33,
    32,
    31,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    ...overlayTeeth,
  ],
  availableAreas: ['tooth', 'tooth.root', 'tooth.crown'],
  _id: '324301662140235987970',
  availableModifiers: [],
  availableLocations: [],
  criticality: 1,
  availableMaterials: [],
};

const sealantFindingOption = {
  name: 'sealant',
  label: 'Sealant',
  rootColor: '',
  crownColor: 'green',
  toothType: 'natural',
  crownType: 'natural',
  procCode: 'D1351',
  availableMaterials: [],
  availablePositions: [
    18,
    17,
    16,
    15,
    14,
    13,
    12,
    11,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    38,
    37,
    36,
    35,
    34,
    33,
    32,
    31,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    ...overlayTeeth,
  ],
};

export const findingOptions = [
  {
    name: 'missing',
    label: 'Missing',
    procCode: '',
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
    availableAreas: ['tooth', 'tooth.root', 'tooth.crown'],
    _id: '32430166214023597088',
    criticality: 1,
    availableMaterials: [],
  },
  { ...sealantFindingOption },
  // {
  //   name: 'veneer',
  //   label: 'Veneer',
  //   rootColor: '',
  //   crownColor: 'celeste',
  //   availablePositions: [
  //     15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 35, 34, 33, 32, 31, 41, 42, 43,
  //     44, 45,
  //   ],
  //   availableAreas: ['tooth', 'tooth.root', 'tooth.crown'],
  //   _id: '324301662140235978970678',
  //   availableModifiers: [],
  //   availableLocations: [],
  //   criticality: 1,
  //   availableMaterials: [],
  // },
  { ...ponticFindingOption },
  {
    name: 'crown',
    label: 'Crown',
    procCode: 'N4118',
    rootColor: '',
    crownColor: 'sky-blue',
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
    availableAreas: ['tooth', 'tooth.root', 'tooth.crown'],
    _id: '324301662140235970sd',
    availableModifiers: [],
    availableLocations: [],
    criticality: 1,
    availableMaterials: ['3-0'],
    // availableMaterials: ['3-0', '3-1', '3-2', '3-3'],
  },
  {
    name: 'filling',
    label: 'Filling',
    procCode: 'D2330',
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
    availableAreas: [
      'tooth.crown.mesial',
      'tooth.crown.distal',
      'tooth.crown.facial',
      'tooth.crown.top',
      'tooth.crown.oral',
    ],
    _id: '323932011218600130',
    availableModifiers: [],
    availableLocations: [],
    criticality: 1,
    availableMaterials: ['4-0', '4-2'],
    // availableMaterials: ['4-0', '4-1', '4-2', '4-3', '4-4', '4-5'],
  },
  {
    name: 'endoTests',
    label: 'Endo Tests',
    procCode: 'D3310',
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
    availableAreas: ['tooth', 'tooth.root', 'tooth.crown'],
    _id: '324301662140235970098',
    availableModifiers: [],
    availableLocations: [],
    criticality: 1,
    availableMaterials: ['5-0', '5-1'],
  },
  {
    name: 'babyOrAdultTeeth',
    label: 'Baby/adult teeth',
    availablePositions: [
      18,
      17,
      16,
      15,
      14,
      13,
      12,
      11,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      38,
      37,
      36,
      35,
      34,
      33,
      32,
      31,
      41,
      42,
      43,
      44,
      45,
      46,
      47,
      48,
      ...overlayTeeth,
    ],
    availableAreas: ['tooth', 'tooth.root', 'tooth.crown'],
    _id: '324301662140235970808',
    availableModifiers: [],
    availableLocations: [],
    criticality: 1,
    availableMaterials: ['7-0', '7-1', '7-2', '7-3', '7-4'],
  },
  { ...extractionMaterial },
];

// sealant - filling composite
findingAvailableMaterials.D1351 = { ...sealantFindingOption };
// resin-based composite - filling composite
findingAvailableMaterials.D2330 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2331 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2332 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2335 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2380 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2381 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2382 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2383 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2385 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2390 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2391 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2392 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2393 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2394 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2650 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2651 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2652 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2662 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2663 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2664 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2710 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D2712 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D6205 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D6710 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D239 = findingAvailableMaterials['4-0'];
findingAvailableMaterials.D292 = findingAvailableMaterials['4-0'];
// amalgam - filling amalgam
findingAvailableMaterials.D2140 = findingAvailableMaterials['4-2'];
findingAvailableMaterials.D2150 = findingAvailableMaterials['4-2'];
findingAvailableMaterials.D2160 = findingAvailableMaterials['4-2'];
findingAvailableMaterials.D2161 = findingAvailableMaterials['4-2'];
findingAvailableMaterials.cra = findingAvailableMaterials['4-2'];
// RCT - RCT
findingAvailableMaterials.D3310 = RctFindingOption;
findingAvailableMaterials.D3320 = RctFindingOption;
findingAvailableMaterials.D3346 = RctFindingOption;
findingAvailableMaterials.D3347 = RctFindingOption;
findingAvailableMaterials.D3348 = RctFindingOption;
// RCT PU, PPF - RCT with trapezium
findingAvailableMaterials.D2950 = RctPuPpfFindingOption;
findingAvailableMaterials.D2750 = RctPuPpfFindingOption;
findingAvailableMaterials.D3330 = RctPuPpfFindingOption;
findingAvailableMaterials.N4118 = RctPuPpfFindingOption;
// Extraction - transparent
findingAvailableMaterials.D7110 = extractionMaterial;
findingAvailableMaterials.D7111 = extractionMaterial;
findingAvailableMaterials.D7120 = extractionMaterial;
findingAvailableMaterials.D7140 = extractionMaterial;
findingAvailableMaterials.D7310 = extractionMaterial;
findingAvailableMaterials.D7311 = extractionMaterial;
findingAvailableMaterials.D7320 = extractionMaterial;
findingAvailableMaterials.D7321 = extractionMaterial;
findingAvailableMaterials['7120'] = extractionMaterial;
findingAvailableMaterials.D7210 = extractionMaterial;
// Bridge - pontic
findingAvailableMaterials['02789'] = ponticFindingOption;
findingAvailableMaterials.D6545 = ponticFindingOption;
findingAvailableMaterials.D6751 = ponticFindingOption;
findingAvailableMaterials.D6793 = ponticFindingOption;
findingAvailableMaterials.D6971 = ponticFindingOption;
findingAvailableMaterials.D6985 = ponticFindingOption;
findingAvailableMaterials['0'] = ponticFindingOption;
findingAvailableMaterials.N4127 = ponticFindingOption;
findingAvailableMaterials.D6750 = ponticFindingOption;
// Crown - crown composite natural
findingAvailableMaterials.N4118 = findingAvailableMaterials['3-0'];

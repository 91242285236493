import { lowerJawTeeth, upperJawTeeth } from '../../constants/teeth/tooth';
import { toothLargeViewCoords } from '../../constants/teeth/coords';

const mapProps = (teeth) => {
  const mappedProps = {};

  teeth.forEach((tooth, index) => {
    if (index < 8) {
      mappedProps[tooth] = {
        toothImage: require(`../../assets/images/teeth/natural${tooth}.png`),
        crownUpperviewImage: require(`../../assets/images/teeth/upperView/natural/${tooth}.png`),
        coords: toothLargeViewCoords[tooth],
      };
    } else {
      mappedProps[tooth] = {
        toothImage: require(`../../assets/images/teeth/natural${teeth[teeth.length - index - 1]}.png`),
        crownUpperviewImage: require(`../../assets/images/teeth/upperView/natural/${
          teeth[teeth.length - index - 1]
        }.png`),
        coords: toothLargeViewCoords[tooth],
        right: true,
      };
    }
  });

  return mappedProps;
};

export const getToothLargeViewProps = () => {
  const upperJawMappedProps = mapProps(upperJawTeeth);
  const lowerJawMappedProps = mapProps(lowerJawTeeth);

  return {
    ...upperJawMappedProps,
    ...lowerJawMappedProps,
    A: { ...upperJawMappedProps[17] },
    B: { ...upperJawMappedProps[16] },
    C: { ...upperJawMappedProps[15] },
    D: { ...upperJawMappedProps[12] },
    E: { ...upperJawMappedProps[11] },
    F: { ...upperJawMappedProps[21] },
    G: { ...upperJawMappedProps[22] },
    H: { ...upperJawMappedProps[25] },
    I: { ...upperJawMappedProps[26] },
    J: { ...upperJawMappedProps[27] },
    K: { ...lowerJawMappedProps[37] },
    L: { ...lowerJawMappedProps[36] },
    M: { ...lowerJawMappedProps[35] },
    N: { ...lowerJawMappedProps[32] },
    O: { ...lowerJawMappedProps[31] },
    P: { ...lowerJawMappedProps[41] },
    Q: { ...lowerJawMappedProps[42] },
    R: { ...lowerJawMappedProps[45] },
    S: { ...lowerJawMappedProps[46] },
    T: { ...lowerJawMappedProps[47] },
  };
};

import { blue, green, red, yellow } from '@mui/material/colors';
import dayjs from 'dayjs';
import { create } from 'zustand';

export const useTeethStore = create((set) => ({
  activeTeethChartTab: 0,
  canEditTreatment: false,
  activeTeeth: [],
  selectedCrownAreas: [],
  activeDateFindings: [],
  visibleFindings: [],
  allFindings: [],
  priorities: [],
  procCodes: [],
  firstProcDate: new Date(),
  appliedFiltersList: [],
  credentials: {
    accessToken: '',
    patientNumber: '',
    appointmentNum: '',
    clinicNum: '',
    provNum: '',
    perioExamNum: '',
  },
  treatmentFormData: {
    procDate: dayjs(new Date()),
    procStatus: 1,
    priority: '',
  },
  indicatorColors: {
    1: {
      color: red[400],
      textColor: 'white',
      description: 'Treatment Plan',
      title: 'TP',
      code: 1,
    },
    2: {
      color: blue[400],
      textColor: 'white',
      description: 'Complete',
      title: 'C',
      code: 2,
    },
    3: {
      color: blue[400],
      textColor: 'white',
      description: 'Existing Current Provider',
      title: 'EC',
      code: 3,
    },
    4: {
      color: green[400],
      textColor: 'white',
      description: 'Existing Other Provider',
      title: 'EO',
      code: 4,
    },
    5: {
      color: yellow[500],
      textColor: 'white',
      description: 'Referred Out',
      title: 'R',
      code: 5,
    },
    6: {
      color: 'white',
      textColor: 'white',
      description: 'Deleted',
      title: 'D',
      code: 6,
    },
    7: {
      color: red[400],
      textColor: 'white',
      description: 'Condition',
      title: 'Cn',
      code: 7,
    },
    8: {
      color: 'white',
      textColor: 'white',
      description: 'Treatment Plan inactive',
      title: 'TPi',
      code: 8,
    },
  },
  primaryTeeth: [],
  permanentTeeth: [],
  showAllPermanentTeeth: true,
  setCrownAreas: (areas) => set({ selectedCrownAreas: areas }),
  setActiveTeeth: (teeth) => set({ activeTeeth: teeth }),
  setActiveDateFindings: (findings) => set({ activeDateFindings: findings }),
  setVisibleFindings: (findings) => set({ visibleFindings: findings }),
  setAllFindings: (findings) => set({ allFindings: findings }),
  setPriorities: (priorities) => set({ priorities }),
  setCredentials: (credentials) => set({ credentials }),
  setFirstProcDate: (procDate) => set({ firstProcDate: procDate }),
  setTreatmentFormData: (data) => set({ treatmentFormData: data }),
  setProcCodes: (procCodes) => set({ procCodes }),
  setEditTreatment: (edit) => set({ canEditTreatment: edit }),
  setIndicatorColors: (colors) => set({ indicatorColors: colors }),
  setActiveTeethChartTab: (activeTab) => set({ activeTeethChartTab: activeTab }),
  setPrimaryTeeth: (teeth) => set({ primaryTeeth: teeth }),
  setPermanantTeeth: (teeth) => set({ permanentTeeth: teeth }),
  setAllPermanent: () => {
    set({
      permanentTeeth: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
        36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48,]
    })
    set({ primaryTeeth: [] })
  },
  setAllPrimary: () => {
    set({
      primaryTeeth: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
        36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48]
    })
    set({ permanentTeeth: [] })
  },
  setAllMixedDentation: () => {
    set({ permanentTeeth: [11, 12, 16, 21, 22, 26, 41, 42, 46, 31, 32, 36] })
    set({ primaryTeeth: [13, 14, 15, 17, 18, 23, 24, 25, 27, 28, 43, 44, 45, 47, 48, 33, 34, 35, 37, 38] })
  },
  setApplyFiltersList: (filter) => set({appliedFiltersList: filter})
}));

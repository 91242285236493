import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useTeethStore } from "../../zustand/teethStore";
import Chip from "@mui/material/Chip";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { Delete } from "@mui/icons-material";
import { deleteProcedureLog } from "../../api/findings";
import { ProcTableFilter } from "./ProcTableFilter";
import { useSnackbar } from "notistack";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: "white",
    fontWeight: 700,
    cursor: "pointer",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const tableHeaders = [
  { id: "ProcDate", label: "DATE", numeric: false, date: true, filter: false },
  {
    id: "ToothNum",
    label: "TOOTH",
    numeric: true,
    filter: true,
    filterProps: {
      field: {
        id: "02",
        label: "Tooth",
        value: "ToothNum",
        type: "number",
      },
    },
  },
  {
    id: "Surf",
    label: "SURFACES",
    numeric: false,
    filter: true,
    filterProps: {
      field: {
        id: "03",
        label: "Surfaces",
        value: "Surf",
        type: "string",
      },
    },
  },
  {
    id: "Descript",
    label: "DESCRIPTION",
    numeric: false,
    filter: true,
    filterProps: {
      field: {
        id: "04",
        label: "Description",
        value: "Descript",
        type: "string",
      },
    },
  },
  {
    id: "ProcStatus",
    label: "STATUS",
    numeric: false,
    filter: true,
    filterProps: {
      field: {
        id: "05",
        label: "Status",
        value: "ProcStatus",
        type: "dropdown",
        options: [
          {
            label: "All",
            value: "",
          },
          ...Object.values(useTeethStore.getState()?.indicatorColors || {}).map(
            (status) => ({
              label: status.description,
              value: status.code,
            }),
          ),
        ],
      },
    },
  },
  {
    id: "ProcCode",
    label: "CODE",
    numeric: false,
    filter: true,
    filterProps: {
      field: {
        id: "06",
        label: "Code",
        value: "ProcCode",
        type: "string",
      },
    },
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ProcTable() {
  const {
    allFindings,
    setAllFindings,
    activeDateFindings,
    visibleFindings,
    setVisibleFindings,
    canEditTreatment,
    indicatorColors,
    setApplyFiltersList,
    appliedFiltersList,
  } = useTeethStore();
  const [orderBy, setOrderBy] = useState("ProcDate");
  const [order, setOrder] = useState("asc");
  const [openDialog, setOpenDialog] = useState(false);
  const [treatmentToDelete, setTreatmentToDelete] = useState(null);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const callDeleteProcedureApi = async () => {
    setOpenDialog(false);

    const allFindingData = [...allFindings];
    const findingIndex = allFindingData.findIndex(
      (finding) => finding.ProcNum === treatmentToDelete.ProcNum,
    );
    const deletedFinding = allFindingData[findingIndex];
    allFindingData.splice(findingIndex, 1, {
      ...deletedFinding,
      ProcStatus: 6,
    });
    setAllFindings(allFindingData);

    try {
      await deleteProcedureLog(treatmentToDelete.ProcNum);
      enqueueSnackbar({
        message: "Procedure deleted successfully!",
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      allFindingData.splice(findingIndex, 1, deletedFinding);
      setAllFindings(allFindingData);
      enqueueSnackbar({
        message: "Failed to delete procedure!",
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }

    setTreatmentToDelete(null);
  };

  // const filterAndSortRows = React.useCallback(
  //   (filterFields = appliedFiltersList) => {
  //     const filteredData = activeDateFindings.filter(
  //       (findingData) =>
  //         filterFields.every((filterFieldData) =>
  //           String(findingData[filterFieldData.key])?.includes(
  //             filterFieldData.value,
  //           ),
  //         ) || findingData?.ProcStatus === indicatorColors[4].code,
  //     );

  //     setVisibleFindings(
  //       stableSort(filteredData, getComparator(order, orderBy)),
  //     );
  //   },
  //   [
  //     appliedFiltersList,
  //     activeDateFindings,
  //     setVisibleFindings,
  //     order,
  //     orderBy,
  //     indicatorColors,
  //   ],
  // );

  const filterAndSortRows = React.useCallback(
    (filterFields = appliedFiltersList) => {
      const filteredData = activeDateFindings.filter(
        (findingData) =>
          filterFields.every((filterFieldData) =>
            Array.isArray(filterFieldData.value) &&
            filterFieldData.value.length > 0
              ? filterFieldData.value.includes(findingData[filterFieldData.key])
              : Array.isArray(filterFieldData.value) &&
                filterFieldData.value.length === 0
              ? String(findingData[filterFieldData.key])
              : String(findingData[filterFieldData.key])?.includes(
                  filterFieldData.value,
                ),
          ) || findingData?.ProcStatus === indicatorColors[4].code,
      );

      setVisibleFindings(
        stableSort(filteredData, getComparator(order, orderBy)),
      );
    },
    [
      appliedFiltersList,
      activeDateFindings,
      setVisibleFindings,
      order,
      orderBy,
      indicatorColors,
    ],
  );

  const handleChangeFilter = (fields) => {
    filterAndSortRows(fields);
  };

  const handleDeleteIconButtonClick = (row) => {
    setOpenDialog(true);
    setTreatmentToDelete(row);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTreatmentToDelete(null);
  };

  React.useEffect(() => {
    filterAndSortRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDateFindings, allFindings, order, orderBy]);

  return (
    <>
      <ProcTableFilter
        onChangeFilter={handleChangeFilter}
        appliedFilters={appliedFiltersList}
        setAppliedFilters={setApplyFiltersList}
      />

      <TableContainer
        id="treatmentLogsList"
        sx={{
          width: "100%",
          maxHeight: 700,
          border: "1px solid rgba(0, 0, 0, .125)",
          borderTop: "none",
        }}
      >
        <Table sx={{ minWidth: 700 }} stickyHeader aria-label="Treatment logs">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align="left"
                  sortDirection={orderBy === header.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : "asc"}
                    onClick={createSortHandler(header.id)}
                  >
                    {header.label}
                    {orderBy === header.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                  {/* {header.filter && (
                    <ProcTableFilter
                      onChangeFilter={handleChangeFilter}
                      appliedFilters={appliedFilters}
                      setAppliedFilters={setAppliedFilters}
                      field={header.filterProps?.field}
                    />
                  )} */}
                </StyledTableCell>
              ))}

              {canEditTreatment && (
                <StyledTableCell align="left" width="100px">
                  ACTIONS
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>

          {visibleFindings?.map((row) => (
            <TableRow key={row.name}>
              <StyledTableCell sx={{ fontWeight: 600 }}>
                {dayjs(row.ProcDate).format("MM/DD/YYYY")}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: 5,
                  maxWidth: 20,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {row.ToothNum}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: 5,
                  maxWidth: 20,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {row.Surf}
              </StyledTableCell>
              <StyledTableCell align="left" sx={{ maxWidth: 200 }}>
                {row.Descript}
              </StyledTableCell>
              <StyledTableCell align="left">
                {indicatorColors[row.ProcStatus]?.description && (
                  <Chip
                    variant="outlined"
                    size="small"
                    sx={{
                      color:
                        indicatorColors[row.ProcStatus]?.color === "white"
                          ? indicatorColors[row.ProcStatus]?.textColor
                          : indicatorColors[row.ProcStatus]?.color,

                      borderColor:
                        indicatorColors[row.ProcStatus]?.color === "white"
                          ? indicatorColors[row.ProcStatus]?.textColor
                          : indicatorColors[row.ProcStatus]?.color,
                    }}
                    label={indicatorColors[row.ProcStatus]?.description}
                  />
                )}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={{
                  width: "10px",
                  maxWidth: "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {row.ProcCode}
              </StyledTableCell>
              {canEditTreatment && (
                <StyledTableCell align="center">
                  <IconButton onClick={() => handleDeleteIconButtonClick(row)}>
                    <Delete />
                  </IconButton>
                </StyledTableCell>
              )}
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Delete Treatment</DialogTitle>

        <DialogContent dividers sx={{ pb: "60px" }}>
          Do you want to delete `{treatmentToDelete?.Descript}` treatment?
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} variant="outlined">
            Cancel
          </Button>
          <Button onClick={callDeleteProcedureApi} variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

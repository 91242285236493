const coords_18_28 = {
  center: '85,135,32',
  bottom:
    '15,206,43,190,48,185,53,174,61,164,67,164,77,167,84,169,92,168,103,165,111,158,115,165,117,171,119,176,130,186,139,194,149,204,145,210,137,218,123,226,106,230,89,232,72,231,51,227,30,220,21,212',
  right:
    '148,33,144,43,142,52,138,64,136,75,132,90,125,101,118,112,113,116,116,125,117,136,118,144,114,149,111,154,117,165,122,176,134,187,144,197,151,204,159,188,169,166,176,143,183,101,182,74,173,53,161,43',
  top: '37,58,45,82,50,105,55,117,59,110,64,107,72,103,82,100,91,102,97,103,102,106,109,109,111,113,117,112,120,107,123,102,126,95,132,87,134,78,137,65,139,59,142,44,147,33,136,28,118,26,102,30,81,35,65,43,49,51',
  left: '35,59,40,73,41,86,46,98,50,113,53,120,50,130,51,137,54,149,58,156,60,161,56,166,49,178,47,184,37,192,26,198,14,204,6,180,0,152,5,120,11,91,18,76,24,67',
};

const coords_17_27 = {
  center: '96,135,36',
  top: '42,48,46,62,53,78,59,93,62,108,65,112,71,105,77,102,86,98,95,96,103,98,111,100,116,103,122,106,126,112,130,115,136,111,142,106,148,95,154,85,159,68,161,61,164,45,164,38,155,33,145,27,132,25,117,26,104,27,85,33,71,38,54,42',
  right:
    '168,40,165,49,160,74,149,99,141,109,131,117,133,128,133,144,129,152,123,162,117,166,120,171,122,179,130,191,141,200,149,205,154,208,159,200,175,176,186,144,193,109,195,83,192,60,182,49',
  bottom:
    '13,201,36,185,52,162,62,156,66,159,73,165,78,167,84,170,93,172,102,172,110,169,116,168,118,172,121,180,124,186,128,191,134,196,140,203,149,207,152,211,147,219,135,227,120,230,108,232,91,235,57,227,32,220,21,212',
  left: '39,51,44,64,50,76,54,86,58,98,59,110,62,116,58,126,57,135,58,144,59,150,62,154,53,158,48,166,40,177,34,184,24,190,13,200,7,190,0,160,2,127,4,97,14,75,25,61',
};

const coords_16_26 = {
  center: '105,131,43',
  top: '33,53,66,40,92,36,116,28,143,22,169,27,181,32,194,42,169,68,152,91,146,101,141,106,137,98,128,89,113,85,97,84,83,88,71,98,65,107,56,103,47,91,40,81',
  right:
    '195,44,182,59,166,76,151,95,144,106,147,115,150,124,150,135,147,144,145,152,142,159,147,166,157,177,166,187,174,199,181,210,188,196,197,166,204,141,211,117,211,91,207,70,202,54',
  bottom:
    '177,212,165,190,151,177,139,160,130,168,118,174,101,177,85,172,74,165,67,157,48,174,32,194,23,222,32,229,52,232,67,235,93,237,114,236,136,235,152,232,166,223,172,219',
  left: '30,53,14,68,6,95,3,116,1,155,1,172,2,186,7,199,14,212,21,219,27,207,30,192,48,171,65,155,60,137,59,125,62,113,61,109,54,104,45,93,40,86,32,60',
};

const coords_15_25 = {
  center: '71,131,26',
  top: '30,60,28,69,29,85,33,95,40,102,44,107,49,109,54,107,61,104,67,102,73,102,80,103,86,106,90,109,94,113,101,111,106,107,112,101,118,94,121,88,124,82,126,75,129,70,123,65,117,58,110,54,102,50,93,47,82,46,67,48,51,53,41,56',
  right:
    '131,73,126,80,122,89,118,95,112,103,106,109,103,115,99,122,99,129,98,135,98,142,94,148,94,154,97,158,100,163,105,168,110,174,114,180,119,175,122,167,126,159,128,150,133,139,135,126,137,118,140,110,140,98,140,88,136,80',
  bottom:
    '17,182,23,176,31,170,37,166,42,160,47,153,53,155,59,156,66,159,72,159,78,159,84,157,91,159,93,164,98,167,101,172,104,177,107,182,106,188,104,194,98,198,91,202,84,207,75,210,66,212,56,212,47,210,37,206,26,195,20,187',
  left: '12,75,18,81,23,85,26,92,31,96,34,101,39,107,41,113,42,119,41,126,41,132,40,140,40,148,37,155,37,161,32,166,26,171,19,174,12,175,7,167,6,156,3,146,2,133,0,122,1,105,4,93,7,84',
};

const coords_14_24 = {
  center: '70,131,28',
  top: '24,61,26,67,33,76,38,86,44,94,50,100,58,102,69,102,80,102,88,106,99,104,108,101,115,94,119,86,121,79,124,73,120,65,115,57,104,50,93,44,78,40,65,38,51,42,38,46',
  right:
    '130,73,125,77,122,85,117,91,113,97,108,103,102,108,97,114,98,120,99,125,99,132,98,141,94,148,92,153,90,160,94,164,99,169,104,175,109,181,110,186,115,189,121,188,126,179,131,168,135,157,139,144,142,132,144,120,146,108,145,94,140,84',
  bottom:
    '12,189,20,187,29,181,36,173,42,163,49,158,58,160,64,160,72,161,80,160,85,165,91,168,97,173,101,179,106,184,111,191,113,197,109,203,105,207,100,212,92,215,85,218,78,220,70,219,62,221,54,218,46,216,37,212,29,207,21,198',
  left: '40,93,43,107,39,123,39,137,38,148,35,162,31,175,20,183,10,185,5,171,4,157,5,142,5,130,4,114,3,100,3,79,11,69,21,65,25,70,33,80',
};

const coords_13_23 = {
  center: '93,127,30',
  top: '68,100,60,95,46,82,44,70,44,59,59,52,72,47,83,43,97,40,112,43,123,44,135,49,147,59,155,69,156,80,150,89,138,96,126,103,113,102,99,96,86,95,77,99',
  right:
    '154,88,140,100,131,107,126,116,126,125,124,136,124,149,129,152,133,160,138,166,142,173,147,177,153,180,159,174,164,166,168,159,173,150,176,139,179,132,179,123,179,114,177,104,173,95,171,86,163,83',
  bottom:
    '79,215,68,212,58,207,46,199,40,191,42,185,46,181,52,175,57,170,62,161,67,155,71,151,76,153,82,155,87,157,92,157,97,157,104,156,110,153,114,156,121,159,128,165,138,170,143,181,142,191,129,202,116,210,103,214,89,215',
  left: '33,75,42,81,53,92,60,105,61,117,62,133,60,145,56,158,45,168,39,172,31,175,22,170,16,159,12,150,4,143,1,131,0,119,4,106,10,93,21,79',
};

const coords_12_22 = {
  center: '80,131,22',
  top: '48,106,60,111,71,109,83,107,99,109,111,97,114,86,98,78,80,75,62,77,46,84,41,89,43,99',
  right:
    '114,92,112,100,105,110,103,120,106,131,109,137,113,141,117,145,122,149,127,153,132,155,136,149,138,142,140,135,140,127,139,117,136,108,132,100,127,92,121,88',
  bottom:
    '119,173,126,164,123,154,113,146,103,147,95,149,87,153,77,155,69,152,62,147,56,150,53,155,48,158,43,162,46,168,52,171,59,176,66,180,73,184,83,186,92,186,103,184,112,178',
  left: '35,89,39,93,43,102,50,112,54,122,54,133,53,142,49,148,44,154,37,160,30,156,25,150,18,144,14,137,10,128,10,115,12,105,16,97,23,91,28,88',
};

const coords_11_21 = {
  center: '91,126,21',
  top: '57,74,60,88,65,101,72,111,79,106,88,103,97,104,105,108,109,111,118,102,130,95,139,85,138,78,124,74,109,69,88,68,73,69',
  right:
    '142,80,141,88,132,95,122,102,115,108,111,114,113,120,113,131,110,139,115,146,122,161,126,172,133,178,144,168,159,150,166,136,166,118,160,97,151,88',
  bottom:
    '42,162,55,157,62,149,70,137,75,142,81,146,89,149,97,149,105,145,109,140,114,147,117,154,121,163,122,171,127,174,130,179,121,184,109,191,94,191,79,186,60,176',
  left: '55,76,56,84,59,89,61,97,66,105,72,115,67,121,68,126,69,132,70,136,65,142,55,155,40,161,28,153,17,144,11,129,13,112,21,98,29,88,41,84,48,79',
};

const coords_48_38 = {
  center: '96,129,25',
  bottom:
    '78,146,85,151,93,154,100,153,107,151,113,147,115,155,119,165,122,175,128,189,131,196,119,201,100,204,83,206,62,205,43,200,31,192,23,185,34,173,56,155',
  left: '72,120,71,126,72,133,73,138,76,144,77,145,70,148,64,152,54,156,49,160,38,169,26,181,23,184,16,178,12,171,2,157,0,143,1,128,6,110,12,93,21,80,30,73,38,67,40,82,47,94,54,105,59,111,65,116',
  top: '39,68,61,58,91,53,122,55,147,63,164,76,153,94,135,108,121,123,113,111,106,106,96,104,85,106,77,111,73,120,61,112,51,98,40,83',
  right:
    '122,123,132,114,140,105,150,98,155,90,164,76,170,83,177,92,180,109,181,123,179,141,173,156,164,173,155,184,142,192,131,196,124,178,118,163,114,148,118,141,121,134',
};

const coords_47_37 = {
  center: '94,124,32',
  bottom:
    '11,163,30,151,52,139,62,134,64,141,69,147,73,151,79,154,86,155,93,157,100,157,99,163,102,170,108,185,114,196,118,202,119,212,111,212,89,206,70,202,46,193,27,184,18,174',
  left: '53,50,54,62,55,75,62,92,66,104,62,114,60,123,62,131,54,135,39,144,22,153,11,161,1,148,0,124,7,101,18,78,26,67,37,57',
  top: '57,49,56,64,59,82,68,101,78,95,87,90,98,91,111,95,118,100,123,108,127,119,127,128,126,134,143,129,163,124,175,109,184,95,183,88,178,80,162,69,140,62,102,52,75,48',
  right:
    '187,95,177,109,164,126,124,136,118,147,105,155,102,163,110,183,122,203,123,212,135,212,153,207,165,199,177,187,186,169,192,145,195,124,193,108',
};

const coords_46_36 = {
  center: '100,122,39',
  bottom:
    '26,189,37,182,47,174,56,167,63,156,67,147,73,153,80,158,92,161,101,162,110,161,122,156,124,161,126,171,131,183,138,193,144,204,155,216,145,217,124,218,91,215,67,210,44,205',
  left: '46,51,23,69,9,91,3,118,0,153,6,165,13,177,23,187,33,183,51,169,63,155,67,144,61,135,60,129,59,117,59,107,52,94,47,82,45,70,46,61',
  top: '50,51,67,45,88,43,120,46,152,53,170,60,180,66,163,84,147,103,138,109,134,101,123,89,105,83,80,86,71,93,66,100,61,105,52,87,47,70',
  right:
    '181,67,195,80,205,93,210,108,211,122,211,146,205,165,199,180,188,198,178,207,157,215,139,191,128,169,125,152,132,145,139,133,139,122,140,113,148,102,160,89,174,75',
};

const coords_45_35 = {
  center: '68,122,23',
  bottom:
    '24,181,40,158,47,145,51,140,58,143,63,145,70,145,79,143,79,149,81,155,84,160,90,173,98,190,92,196,80,201,67,203,56,202,43,197,33,190',
  left: '26,69,28,87,42,111,44,116,44,127,47,134,50,138,47,144,35,162,23,180,13,168,1,151,1,132,3,109,8,92,17,78',
  top: '29,68,45,61,61,57,77,63,95,65,112,69,123,76,120,88,111,101,99,115,92,118,90,110,81,101,71,98,57,99,49,106,45,112,38,99,31,87',
  right:
    '92,121,99,117,108,109,112,102,121,91,125,77,131,84,138,96,140,107,140,122,140,131,140,149,137,159,125,173,114,180,105,185,100,189,92,173,86,158,80,147,85,139,89,134,92,128',
};

const coords_44_34 = {
  center: '70,119,22',
  bottom:
    '49,145,50,135,57,139,65,141,76,143,83,138,84,149,87,166,93,180,106,190,94,198,72,205,55,203,41,197,29,188,39,169,48,155',
  left: '24,75,8,90,2,107,2,129,5,150,15,172,27,185,33,176,42,162,46,153,48,136,48,127,46,117,47,108,37,100,30,85',
  top: '26,72,39,99,49,108,55,99,70,95,87,100,94,112,108,107,118,92,122,81,108,67,87,57,69,54,46,61',
  right:
    '124,81,116,98,109,109,92,114,92,127,85,136,87,157,93,173,108,189,120,178,129,168,140,158,147,142,145,120,138,103',
};

const coords_43_33 = {
  center: '89,130,20',
  top: '46,86,67,74,88,68,107,72,124,84,133,92,127,103,113,120,109,124,105,115,97,109,88,108,78,112,70,118,69,124,63,120,58,113,51,103',
  right:
    '110,126,118,116,126,106,135,93,141,98,150,108,158,120,161,130,162,137,158,145,154,154,146,164,137,173,129,178,120,184,109,173,104,161,97,150,102,147,107,141,110,133',
  bottom:
    '43,170,47,161,57,154,67,149,71,142,76,146,81,149,89,151,95,150,99,155,102,161,105,167,108,174,118,185,108,190,96,192,80,193,62,187,50,177',
  left: '44,87,49,102,60,119,67,125,68,133,70,141,67,147,58,152,46,160,41,169,32,157,23,142,17,126,19,108,32,95',
};

const coords_42_32 = {
  center: '76,132,18',
  top: '46,94,63,84,74,79,88,75,100,82,111,91,117,100,122,106,114,117,101,126,95,127,92,121,87,115,79,113,69,114,63,118,57,124,51,116,47,105',
  right:
    '123,108,115,118,105,124,96,128,95,136,90,147,93,155,102,167,113,182,127,175,135,163,141,147,140,130,132,119',
  bottom:
    '36,165,43,157,50,147,59,140,63,146,69,150,76,150,83,150,88,149,91,155,95,160,100,167,111,183,100,186,82,186,65,183,54,179',
  left: '44,95,45,104,48,113,53,122,57,127,56,134,56,140,50,145,42,154,37,160,32,164,25,156,17,146,12,133,14,117,24,106',
};

const coords_41_31 = {
  center: '88,133,17',
  top: '58,94,62,107,73,122,81,115,91,115,100,119,106,127,113,125,125,116,129,106,121,97,107,90,95,86,83,84,72,86',
  right:
    '108,129,111,128,117,124,126,118,131,107,136,114,142,122,148,132,148,144,148,154,145,161,137,170,125,168,114,159,106,154,101,147,105,140,105,135',
  bottom:
    '54,169,59,157,66,147,72,142,77,148,84,151,92,151,99,147,101,151,109,158,117,163,123,168,132,171,126,175,116,176,97,177,77,177,63,174',
  left: '56,95,59,103,64,112,72,123,70,130,70,135,72,141,67,144,57,157,54,166,48,167,38,158,31,146,27,134,31,123,39,112,46,104',
};

export const toothLargeViewCoords = {
  18: coords_18_28,
  17: coords_17_27,
  16: coords_16_26,
  15: coords_15_25,
  14: coords_14_24,
  13: coords_13_23,
  12: coords_12_22,
  11: coords_11_21,
  28: coords_18_28,
  27: coords_17_27,
  26: coords_16_26,
  25: coords_15_25,
  24: coords_14_24,
  23: coords_13_23,
  22: coords_12_22,
  21: coords_11_21,

  48: coords_48_38,
  47: coords_47_37,
  46: coords_46_36,
  45: coords_45_35,
  44: coords_44_34,
  43: coords_43_33,
  42: coords_42_32,
  41: coords_41_31,
  38: coords_48_38,
  37: coords_47_37,
  36: coords_46_36,
  35: coords_45_35,
  34: coords_44_34,
  33: coords_43_33,
  32: coords_42_32,
  31: coords_41_31,
};

import { styled } from '@mui/material';

export const StyledImage = styled('img', {
  shouldForwardProp: (prop) => prop !== 'right',
})(({ right }) => ({
  maxWidth: '100%',
  height: 'auto',
  display: 'block',
  transform: right ? 'scaleX(-1)' : 'none',
}));

import axios from "axios";
import { BASE_API_URL, apiCredentials } from "./config";

const client = axios.create({
  baseURL: BASE_API_URL,
});

client.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json";

  if (apiCredentials.accessToken) {
    config.headers["token"] = apiCredentials.accessToken;
  }

  return config;
});

const request = function (options) {
  const onSuccess = function (response) {
    return response.data;
  };

  const onError = function (error) {
    throw new Error(error.message, { cause: error });
  };

  return client(options).then(onSuccess).catch(onError);
};

export default request;

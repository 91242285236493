export const xAxisPaddings = {
  18: {
    left: 7,
    right: 8,
  },
  17: {
    left: 8,
    right: 9,
  },
  16: {
    left: 8,
    right: 9,
  },
  15: {
    left: 7,
    right: 7,
  },
  14: {
    left: 7,
    right: 7,
  },
  13: {
    left: 9,
    right: 11,
  },
  12: {
    left: 2,
    right: 14,
  },
  11: {
    left: 7,
    right: 10,
  },
  28: {
    left: 10,
    right: 5,
  },
  27: {
    left: 9,
    right: 8,
  },
  26: {
    left: 9,
    right: 8,
  },
  25: {
    left: 8,
    right: 6,
  },
  24: {
    left: 9,
    right: 5,
  },
  23: {
    left: 12,
    right: 8,
  },
  22: {
    left: 14,
    right: 2,
  },
  21: {
    left: 10,
    right: 7,
  },
  48: {
    left: 10,
    right: 3,
  },
  47: {
    left: 3,
    right: 12,
  },
  46: {
    left: 9,
    right: 3,
  },
  45: {
    left: 5,
    right: 9,
  },
  44: {
    left: 6,
    right: 8,
  },
  43: {
    left: 12,
    right: 13,
  },
  42: {
    left: 8,
    right: 12,
  },
  41: {
    left: 13,
    right: 13,
  },
  38: {
    left: 3,
    right: 10,
  },
  37: {
    left: 12,
    right: 3,
  },
  36: {
    left: 3,
    right: 8,
  },
  35: {
    left: 10,
    right: 4,
  },
  34: {
    left: 10,
    right: 4,
  },
  33: {
    left: 13,
    right: 12,
  },
  32: {
    left: 12,
    right: 8,
  },
  31: {
    left: 13,
    right: 13,
  },
};

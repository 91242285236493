import React from 'react';
import PropTypes from 'prop-types';
import { StyledTab, StyledTabs } from './styles';
import { Box } from '@mui/material';

const NullComponent = () => null;

export const FilledTab = ({ tabs = [], value, onChange, TabProps = {} }) => {
  return (
    <Box>
      <StyledTabs
        value={value}
        onChange={onChange}
        {...TabProps}
      >
        {tabs?.map((tabData, index) => (
          <StyledTab
            value={index}
            label={tabData.label}
            key={tabData.label}
            {...(tabData?.props || {})}
          />
        ))}
      </StyledTabs>

      {tabs.map((tabData, index) => {
        const Component = tabData?.component || NullComponent;

        return (
          <CustomTabPanel
            key={tabData.label}
            value={value}
            index={index}
          >
            <Component {...(tabData?.componentProps || {})} />
          </CustomTabPanel>
        );
      })}
    </Box>
  );
};

FilledTab.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      props: PropTypes.objectOf(PropTypes.any),
      component: PropTypes.node,
      componentProps: PropTypes.objectOf(PropTypes.any),
    })
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  TabProps: PropTypes.objectOf(PropTypes.any),
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

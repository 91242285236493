import { Tab, Tabs, styled } from '@mui/material';

export const StyledTabs = styled(({ ...props }) => (
  <Tabs
    TabIndicatorProps={{ sx: { display: 'none' } }}
    {...props}
  />
))(({ theme }) => ({
  '&.MuiTabs-root': {
    minHeight: '45px',
    maxHeight: '45px',
    borderRadius: '18px',
    width: 'fit-content',
    // background: theme.palette.gray[600],
    boxShadow: '0px 2px 7px 1px rgba(0, 0, 0, 0.15)',

    '& .Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: '#3cbbeb',
      borderRadius: '15px',
      boxShadow: '0px 1px 4px 1px rgba(0, 0, 0, 0.15)',
    },
  },

  '& .MuiTabs-flexContainer': {
    gap: '5px',
    marginBlock: '4px',
    marginInline: '4px',
  },
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 'unset',
  padding: '7px 8px 6px 9px',
  fontSize: '14px',
  fontWeight: 600,
  lineHeight: '24px',
  textTransform: 'capitalize',
  letterSpacing: '1px',
  // color: `${theme.palette.gray[500]}50`,

}));

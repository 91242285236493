import { findingAvailableMaterials } from '../../../constants/findings/findingOptions';

export const useToothInformation = (_tooth, findings, missing) => {
  const toothFinding = findings?.filter((finding) => finding?.area === 'tooth' || !finding?.Surf)[0] || {};

  if (missing) {
    return {
      toothType: 'missing',
      crownType: 'missing',
    };
  }

  const toothType =
    findingAvailableMaterials[toothFinding?.findingPath || toothFinding?.ProcCode]?.toothType || toothFinding?.finding;
  const crownType =
    findingAvailableMaterials[toothFinding?.findingPath || toothFinding?.ProcCode]?.crownType || toothFinding?.finding;

  return { toothType, crownType };
};

import React from 'react';
import ProcTable from './ProcTable';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Accordion, AccordionDetails, AccordionSummary } from './common/styles';
import VisualizationIndicators from './VisualizationIndicators';
import { useMediaQuery, useTheme } from '@mui/material';

export default function TreatmentInformation() {
  const theme = useTheme();
  const isLaptopScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Box
        px={{ sm: 2, md: 4, lg: 6 }}
        pb={5}
      >
        {!isLaptopScreen && <VisualizationIndicators />}
        <Accordion defaultExpanded>
          <AccordionSummary
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <Typography fontWeight={700}>Treatment Logs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ProcTable />
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  );
}

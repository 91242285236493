import React from "react";
import { FilledTab } from "../../Mui/Tab/Filled";
import { useTeethStore } from "../../../zustand/teethStore";
import { Box } from "@mui/material";
import {
  setToothInitial,
  updateAllMixedDentition,
  updateAllPermanent,
  updateAllPrimary,
} from "../../../api/findings";

const tabItems = [
  { label: "Set All Permanent", value: 0 },
  { label: "Set All Primary", value: 1 },
  { label: "Set Mixed dentition", value: 2 },
  // { label: "Set Response", value: 3 },
];

export const TeethChartsTab = () => {
  const {
    setActiveTeethChartTab,
    activeTeethChartTab,
    setAllPermanent,
    setAllPrimary,
    setAllMixedDentation,
    credentials,
    setPrimaryTeeth, setPermanantTeeth
  } = useTeethStore();

  const setStatesFromApi = async () => {
    try {
      const response = await setToothInitial(credentials.patientNumber);
      const primaryTeethArray = [];
      let permanentTeethArray = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28, 38, 37,
        36, 35, 34, 33, 32, 31, 41, 42, 43, 44, 45, 46, 47, 48];

      response.forEach(tooth => {
        console.log(tooth)
        const num = Number(tooth.ToothNum)
        permanentTeethArray = permanentTeethArray.filter(value => value !== num)
        if (tooth.InitialType === 2) {
          primaryTeethArray.push(num);
        }
      });
      console.log(primaryTeethArray.length, permanentTeethArray.length)
      setPrimaryTeeth(primaryTeethArray);
      setPermanantTeeth(permanentTeethArray);
    } catch (error) {
      console.log("Error setting tooth states from API:", error);
    }
  };

  const handleTabChange = async (_e, activeTab) => {
    setActiveTeethChartTab(activeTab);
    if (activeTab === 0) {
      setAllPermanent();
      updateAllPermanent(
        credentials.patientNumber
      );
    } else if (activeTab === 1) {
      setAllPrimary();
      updateAllPrimary(credentials.patientNumber);
    } else if (activeTab === 2) {
      setAllMixedDentation();
      updateAllMixedDentition(credentials.patientNumber);
    } else if (activeTab === 3) {
      setStatesFromApi();
    }
  };


  return (
    <Box mb={3}>
      <FilledTab
        tabs={tabItems}
        value={activeTeethChartTab}
        onChange={handleTabChange}
        style={{ display: activeTeethChartTab === 3 ? 'none' : 'block' }}
      />
    </Box>
  );
};

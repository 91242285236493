import React, { useState } from "react";
import {
  findingAvailableMaterials,
  findingOptions,
} from "../../constants/findings/findingOptions";
import { Stack, Typography } from "@mui/material";
import Drawer from "../Drawer";
import SubFinding from "./SubFinding";
import { useTeethStore } from "../../zustand/teethStore";
import dayjs from "dayjs";
import { getToothDisplayNumber } from "../TeethDiagram/helper";
import { insertMissingTooth, insertProcedureLog } from "../../api/findings";
import { useSnackbar } from "notistack";
import AddTreatmentForm from "../AddTreatmentForm";

const procStatusMap = {
  0: 1,
  1: 3,
  2: 5,
  3: 4,
};

export default function Finding({
  open,
  onHideFinding,
  onAddingFindingSuccess,
}) {
  const [optionToShow, setOptionsToShow] = useState([]);
  const [selectedOptionPaths, setSelectedOptionPaths] = useState([]);
  const {
    activeTeeth,
    selectedCrownAreas,
    credentials,
    setCrownAreas,
    setActiveTeeth,
    procCodes,
    treatmentFormData: { procStatus, procDate, priority },
  } = useTeethStore();
  const { enqueueSnackbar } = useSnackbar();

  const handleFindingOptionSelect = (option, optionPath) => {
    if (!option?.availableMaterials?.length && findingOptions[optionPath]) {
      handleSaveFinding(null, [optionPath]);

      return;
    }

    const selectedOption = [...selectedOptionPaths];
    const selectedOptionIndex = selectedOption.findIndex(
      (option) => option === optionPath,
    );

    const isSameLevel =
      optionPath.length === selectedOption[selectedOption.length - 1]?.length;

    if (isSameLevel) {
      if (selectedOptionIndex !== -1) {
        selectedOption.splice(selectedOptionIndex, 1);
      } else {
        selectedOption.pop();
        selectedOption.push(optionPath);
      }
    } else if (selectedOptionIndex !== -1) {
      selectedOption.splice(selectedOptionIndex, 1);
    } else {
      selectedOption.push(optionPath);
    }

    setSelectedOptionPaths(selectedOption);
    if (option?.availableMaterials?.length) {
      setOptionsToShow(option?.availableMaterials);
    }
  };

  const handleClearButtonClick = () => {
    const selectedOption = [...selectedOptionPaths];
    selectedOption.pop();
    setSelectedOptionPaths(selectedOption);

    if (selectedOption.length === 1) {
      setOptionsToShow(findingOptions[selectedOption[0]]?.availableMaterials);
      return;
    }

    if (selectedOption.length > 1) {
      const lastSelectedOption = selectedOption[selectedOption.length - 1];
      const availableMaterials =
        findingAvailableMaterials[lastSelectedOption]?.availableMaterials;
      setOptionsToShow(availableMaterials);
    }
  };

  const createApiBodyData = (tooth, finding, surfaces = "") => ({
    PatNum: credentials.patientNumber,
    AptNum: credentials.appointmentNum,
    ProcDate: dayjs(procDate).format("YYYY-MM-DD"),
    ProcFee: "",
    Surf: surfaces,
    ToothNum: getToothDisplayNumber(tooth),
    Priority: priority,
    ProcStatus: Number(procStatus),
    ProvNum: Number(credentials.provNum),
    DateOriginalProsth: "0001-01-01",
    ClinicNum: credentials.clinicNum,
    ProcCode: finding?.procCode,
    CodeNum: procCodes.find((proc) => proc?.ProcCode === finding?.procCode)
      ?.CodeNum,
  });

  const callInsertProcedureLogApi = async (findingsToAdd) => {
    try {
      await insertProcedureLog({ data: findingsToAdd });
      onAddingFindingSuccess();
      enqueueSnackbar({
        message: "Procedure created successfully!",
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to create procedure!",
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }

    setActiveTeeth([]);
    setCrownAreas([]);
  };

  const callInsertMissingTeethApi = async (findingsToAdd) => {
    try {
      await insertMissingTooth({ data: findingsToAdd });
      onAddingFindingSuccess();
      enqueueSnackbar({
        message: "Missing tooth added successfully!",
        variant: "success",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to add missing tooth!",
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }

    setActiveTeeth([]);
    setCrownAreas([]);
  };

  const handleSaveFinding = (_event, optionsPath) => {
    const paths = optionsPath || selectedOptionPaths;
    let finding = null;

    if (paths?.length) {
      if (paths?.length > 1) {
        finding =
          findingAvailableMaterials[
            selectedOptionPaths[selectedOptionPaths.length - 1]
          ];
      } else {
        finding = findingOptions[paths[0]];
      }
    }

    if (finding) {
      const mappedFindings = [];

      // Adding missing tooth
      if (finding.name === "missing") {
        activeTeeth.forEach((tooth) => {
          mappedFindings.push({
            PatNum: credentials.patientNumber,
            ToothNum: getToothDisplayNumber(tooth),
          });
        });

        callInsertMissingTeethApi(mappedFindings);
        return;
      }

      let surfaces = {};

      if (selectedCrownAreas.length) {
        selectedCrownAreas.forEach((crownArea) => {
          const splitedArea = crownArea?.area?.split("-");
          surfaces[`${splitedArea[0]}`] = (
            surfaces[`${splitedArea[0]}`] || ""
          ).concat(splitedArea[1]);
        });

        activeTeeth.forEach((tooth) => {
          if (surfaces[tooth]) {
            mappedFindings.push(
              createApiBodyData(tooth, finding, surfaces[tooth]),
            );
          }
        });
      } else {
        activeTeeth.forEach((tooth) => {
          mappedFindings.push(createApiBodyData(tooth, finding));
        });
      }

      const modifiedProcStatusFindings = mappedFindings.map((data) => ({
        ...data,
        ProcStatus: procStatusMap[data.ProcStatus],
      }));

      callInsertProcedureLogApi(modifiedProcStatusFindings);
    }

    setOptionsToShow([]);
    setSelectedOptionPaths([]);
  };

  const resetStates = () => {
    setOptionsToShow([]);
    setCrownAreas([]);
    setSelectedOptionPaths([]);
    onHideFinding();
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={open}
        onClose={resetStates}
        showProcedureForm={true}
        width="800px"
        maxWidth="800px"
        id={"findingdrawer"}
      >
        <Stack direction="row" pt={3} alignItems="flex-start" gap={2} mx="auto">
          <div className="flex">
            <AddTreatmentForm />
          </div>
        </Stack>
        <Stack
          direction="row"
          // py={6.5}
          px={1}
          // justifyContent="center"
          // alignItems="center"
          flexWrap={"wrap"}
          gap={2}
          mx="auto"
        >
          {findingOptions.map((option, optionIndex) => {
            const shouldRender = procCodes?.find(
              (propCodeData) => propCodeData?.ProcCode === option?.procCode,
            );

            if (!shouldRender && option?.name !== "missing") {
              return null;
            }

            return (
              <React.Fragment key={option._id}>
                <Stack
                  onClick={() => handleFindingOptionSelect(option, optionIndex)}
                  padding={2}
                  border="1px solid"
                  borderColor="grey.400"
                  borderRadius="12px"
                  flex="0 0 180px"
                  alignItems="center"
                  sx={{ cursor: "pointer", ":hover": { opacity: 0.7 } }}
                  flexDirection={"row"}
                >
                  <Typography>{option.label}</Typography>
                </Stack>
              </React.Fragment>
            );
          })}
          {!!optionToShow?.length && !!activeTeeth.length && (
            <SubFinding
              open={!!optionToShow?.length && !!activeTeeth.length}
              onClose={resetStates}
              options={optionToShow}
              selectedOptionPaths={selectedOptionPaths}
              onSelectOption={handleFindingOptionSelect}
              onSave={handleSaveFinding}
              onClear={handleClearButtonClick}
            />
          )}
        </Stack>
      </Drawer>
    </>
  );
}

import * as React from "react";
import PropTypes from "prop-types";
import MuiTabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Stack, useTheme, useMediaQuery, Button } from "@mui/material";
import { blue } from "@mui/material/colors";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Tabs({ tabs = [], showHideFilter, clearFilter }) {
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
    clearFilter(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          gap="24px"
          justifyContent={"space-between"}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <MuiTabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {tabs.map((tab) => (
                <Tab label={tab.label} key={tab.label} />
              ))}
            </MuiTabs>
          </Box>
          <Box>
            {value == 0 && isLaptopScreen && (
              <Box>
                <Button
                  sx={{
                    minWidth: "200px",
                    width: "100%",
                    maxWidth: { xs: "100%", lg: "100%" },
                    mb: { xs: "16px", lg: "unset" },
                    backgroundColor: blue[700],
                  }}
                  variant="contained"
                  size="sm"
                  fullWidth
                  onClick={() => showHideFilter(true)}
                  startIcon={<SearchOutlinedIcon />}
                >
                  Filter Treatment
                </Button>
              </Box>
            )}
          </Box>
        </Stack>
      </Box>
      <Box sx={{ width: "100%" }}>
        {tabs.map((tab, index) => {
          const TabContent = tab.content || <></>;

          return (
            <CustomTabPanel value={value} index={index}>
              <TabContent />
            </CustomTabPanel>
          );
        })}
      </Box>
    </>
  );
}

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import React from "react";

export const Datepicker = ({ label, value, onChange, sx = {}, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker", "DatePicker"]} sx={sx}>
        <DatePicker
          label={label}
          value={value}
          slotProps={{ textField: { size: "small" } }}
          onChange={onChange}
          format="MM/DD/YYYY"
          {...props}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

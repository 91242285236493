import React, { useMemo } from "react";
import Tooth from "../Tooth";
import {
  filterDeletedTreatments,
  findTreatmentsByEoProcStatus,
  getStylesByTooth,
  hasMissingTooth,
  hasProcedureDeleted,
} from "../helper";
import naturalToothImage from "../../../assets/images/teeth/natural41.png";
import naturalCrownAreaImage from "../../../assets/images/teeth/upperView/natural/41.png";
import implant41 from "../../../assets/images/teeth/implant/root/41.png";
import crownPart41 from "../../../assets/images/teeth/crownParts/41.png";
import babyTooth41 from "../../../assets/images/teeth/babyTooth/41.png";
import babyToothMissing41 from "../../../assets/images/teeth/missing/babyToothMissing/root/41.png";
import babyCrownMissing41 from "../../../assets/images/teeth/missing/babyToothMissing/crown/41.png";
import implantCrownPart41 from "../../../assets/images/teeth/crownParts/implant/41.png";
import babyNaturalCrownArea from "../../../assets/images/teeth/upperView/natural/baby/41.png";
import notYetDeveloped from "../../../assets/images/teeth/notYetDeveloped/blank.png";
import rootCanal from "../../../assets/images/teeth/rootCanal/rootCanal.svg";
import { findingAvailableMaterials } from "../../../constants/findings/findingOptions";
import rctTrapezium from "../../../assets/images/teeth/rootCanal/rct-trapezium.png";
import { useToothInformation } from "../hooks/useToothInformation";

const crownAreaImageMap = {
  missing: naturalCrownAreaImage,
  natural: naturalCrownAreaImage,
  implant: naturalCrownAreaImage,
  babyNatural: babyNaturalCrownArea,
  babyToothMissing: babyCrownMissing41,
  notYetDeveloped: notYetDeveloped,
  veneer: naturalCrownAreaImage,
  pontics: naturalCrownAreaImage,
};

const toothImageMap = {
  missing: naturalToothImage,
  natural: naturalToothImage,
  implant: implant41,
  babyNatural: babyTooth41,
  babyToothMissing: babyToothMissing41,
  notYetDeveloped: notYetDeveloped,
  veneer: naturalToothImage,
  pontics: naturalToothImage,
};

const crownPartImageMap = {
  natural: crownPart41,
  veneer: crownPart41,
  implant: implantCrownPart41,
  pontics: crownPart41,
};

export default function Tooth31({
  finding = "natural",
  toothNumber,
  overlayTooth,
  primary,
  insideOverlay,
  permanent,
  secondaryTooth,
}) {
  const activeTreatments =
    filterDeletedTreatments(finding);
  const treatmentsByEoProcStatus =
    findTreatmentsByEoProcStatus(
      activeTreatments
    );
  const findingsToTravers = useMemo(() => {
    if (treatmentsByEoProcStatus?.length) {
      return [{ ...treatmentsByEoProcStatus[0] }];
    }
    if (activeTreatments?.length) {
      return [{ ...activeTreatments[0] }];
    }

    return [];
  }, [
    activeTreatments,
    treatmentsByEoProcStatus,
  ]);

  const missing = hasMissingTooth(
    treatmentsByEoProcStatus || activeTreatments
  );
  const styles = getStylesByTooth(
    41,
    findingsToTravers,
    missing
  );
  const { crownType, toothType } =
    useToothInformation(
      31,
      findingsToTravers,
      missing
    );

  const renderToothImageChildren = useMemo(() => {
    if (missing) {
      return null;
    }

    const findingData = findingsToTravers?.find(
      (finding) =>
        finding?.finding === "rct" ||
        (findingAvailableMaterials[
          finding?.ProcCode
        ]?.name?.startsWith("rct") &&
          !hasProcedureDeleted(finding))
    );

    if (findingData) {
      return (
        <>
          <img
            src={rootCanal}
            alt="RCT-31"
            className="root-rct-31"
          />

          {findingAvailableMaterials[
            findingData?.ProcCode
          ]?.name === "rctPuPpf" && (
            <img
              src={rctTrapezium}
              alt="RCT-31"
              className="root-rct_pu_ppf-31"
            />
          )}
        </>
      );
    }

    return null;
  }, [findingsToTravers, missing]);

  return (
    <Tooth
      lowerJaw
      right
      overlayTooth={overlayTooth}
      tooth={toothNumber || 31}
      crownAreaImage={
        crownAreaImageMap[crownType] ||
        crownAreaImageMap.natural
      }
      toothImage={
        toothImageMap[toothType] ||
        toothImageMap.natural
      }
      crownPartImage={
        crownPartImageMap[crownType]
      }
      toothWrapperClassName={
        styles[toothType]?.toothWrapper ||
        styles.natural.toothWrapper
      }
      crownAreaWrapperClassName={
        styles[crownType]
          ?.crownAreaImageWrapper ||
        styles.natural.crownAreaImageWrapper
      }
      toothImageWrapperClassName={
        styles[toothType]?.toothImageWrapper ||
        styles.natural.toothImageWrapper
      }
      crownPartClassName={
        styles[crownType]?.toothCrownPart
      }
      toothImageChildren={
        renderToothImageChildren
      }
      firstFinding={findingsToTravers[0] || {}}
      primary={primary}
      insideOverlay={insideOverlay}
      permanent={permanent}
      secondaryTooth={secondaryTooth}
    />
  );
}

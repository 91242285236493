import {
  DISTAL,
  FACIAL,
  MESIAL,
  ORAL,
  LINGUAL,
} from '../../constants/teeth/areas';

export const checkShouldApplyFindingOnCrownArea = (areas) =>
  areas?.some(
    (area) =>
      area?.includes(MESIAL) ||
      area?.includes(DISTAL) ||
      area?.includes(FACIAL) ||
      area?.includes(LINGUAL) ||
      area?.includes(ORAL)
  );

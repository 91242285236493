import React from 'react';
import TeethDiagram from '../components/TeethDiagram/TeethDiagram';
import { Navigate } from 'react-router-dom';
import { NotFound404 } from '../pages/404';
import { Forbidden403 } from '../pages/403';
import Periodental from '../pages/Periodental';

export const routes = (isLoggedIn) => {
  return [
    {
      path: '/tooth-chart',
      element: <TeethDiagram />,
    },
    {
      path: '/404',
      element: <NotFound404 />,
    },
    {
      path: '/403',
      element: <Forbidden403 />,
    },
    {
      path: '/perio',
      element: <Periodental />,
    },
    {
      path: '*',
      element: (
        <Navigate
          to='/404'
          replace
        />
      ),
    },
  ];
};

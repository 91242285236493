import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  InputAdornment,
} from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Datepicker } from "../Mui/Datepicker";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import { createProcDate } from "./helper";

export const ProcTableFilter = ({
  onChangeFilter,
  appliedFilters,
  setAppliedFilters,
  handleFieldChange,
  field,
  showLabel = false,
  label = "",
  fullWidth = false,
  fieldFilterValue,
}) => {
  const [fieldValue, setFieldValue] = useState(fieldFilterValue);
  const handleFieldValueChange = (value) => {
    setFieldValue(value);
    handleFieldChange(field.value, value);
    const appliedFiltersData = [...appliedFilters];
    const fieldIndex = appliedFiltersData.findIndex(
      (filterData) => filterData.key === field?.value,
    );

    if (fieldIndex !== -1) {
      appliedFiltersData[fieldIndex] = {
        ...appliedFiltersData[fieldIndex],
        value,
      };
    } else {
      appliedFiltersData.push({ key: field?.value, value: value });
    }

    setAppliedFilters(appliedFiltersData);
  };

  const renderChipValue = (value) => {
    let filterOption = field?.options.filter((item) => item.value === value);
    if (filterOption.length > 0) return filterOption[0];
    return null;
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChangeFilter(appliedFilters);
    }, [500]);

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]);

  return (
    <Box mt="8px">
      {!!field && (
        <>
          {field.type === "string" && (
            <>
              {showLabel && (
                <InputLabel
                  id="dropdown-value-select-label"
                  style={{ marginBottom: "6px" }}
                >
                  {label}
                </InputLabel>
              )}
              <TextField
                size="small"
                value={fieldValue}
                sx={{ minWidth: "150px", marginBottom: "5px" }}
                onChange={(event) => {
                  handleFieldValueChange(event.target.value);
                }}
                fullWidth={fullWidth}
                InputProps={{
                  endAdornment: fieldValue && (
                    <InputAdornment position="end">
                      <CancelOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFieldValueChange("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {field.type === "number" && (
            <>
              {showLabel && (
                <InputLabel
                  id="dropdown-value-select-label"
                  style={{ marginBottom: "6px" }}
                >
                  {label}
                </InputLabel>
              )}
              <TextField
                size="small"
                value={fieldValue}
                sx={{ minWidth: "150px", marginBottom: "5px" }}
                onChange={(event) => {
                  handleFieldValueChange(event.target.value);
                }}
                fullWidth={fullWidth}
                InputProps={{
                  endAdornment: fieldValue && (
                    <InputAdornment position="end">
                      <CancelOutlined
                        style={{ cursor: "pointer" }}
                        onClick={() => handleFieldValueChange("")}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </>
          )}

          {field.type === "dropdown" && (
            <>
              {showLabel && (
                <InputLabel
                  id="dropdown-value-select-label"
                  style={{ marginBottom: "6px" }}
                >
                  {label}
                </InputLabel>
              )}
              <FormControl
                fullWidth={fullWidth}
                size="small"
                sx={{ minWidth: "150px", marginBottom: "5px" }}
                style={{
                  zIndex: 10000,
                }}
              >
                {/* <InputLabel id="dropdown-value-select-label">Value</InputLabel> */}
                <Select
                  labelId="dropdown-value-select-label"
                  value={fieldValue}
                  style={{
                    zIndex: 10000,
                  }}
                  multiple
                  onChange={(e) => handleFieldValueChange(e.target.value)}
                  renderValue={(selected) =>
                    selected.map((select) => {
                      let option = renderChipValue(select);
                      return (
                        <Chip
                          className="mr-1"
                          variant="outlined"
                          size="small"
                          label={option.label}
                          sx={{
                            color:
                              option?.color === "white"
                                ? option?.textColor
                                : option?.color,

                            borderColor:
                              option?.color === "white"
                                ? option?.textColor
                                : option?.color,
                          }}
                        />
                      );
                    })
                  }
                >
                  {field?.options?.map((option) => (
                    <MenuItem
                      key={option.label}
                      value={option.value}
                      // onClick={() => handleDropDownValueChange(option.value)}
                      style={{
                        color: "red",
                      }}
                    >
                      <Checkbox
                        checked={fieldValue.indexOf(option.value) > -1}
                      />
                      <Chip
                        variant="outlined"
                        size="small"
                        label={option.label}
                        sx={{
                          color:
                            option?.color === "white"
                              ? option?.textColor
                              : option?.color,

                          borderColor:
                            option?.color === "white"
                              ? option?.textColor
                              : option?.color,
                        }}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}

          {field.type === "date" && (
            <>
              {showLabel && (
                <InputLabel id="dropdown-value-select-label">
                  {label}
                </InputLabel>
              )}
              <Datepicker
                label="Value"
                value={dayjs(fieldValue)}
                onChange={(value) =>
                  handleFieldValueChange(createProcDate(value))
                }
              />
            </>
          )}
        </>
      )}
    </Box>
  );
};

import {
  Box,
  Divider,
  Stack,
} from "@mui/material";
import React, { useCallback } from "react";
import Tooth17 from "./tooth/Tooth17";
import Tooth16 from "./tooth/Tooth16";
import Tooth15 from "./tooth/Tooth15";
import Tooth12 from "./tooth/Tooth12";
import Tooth11 from "./tooth/Tooth11";
import Tooth21 from "./tooth/Tooth21";
import Tooth22 from "./tooth/Tooth22";
import Tooth25 from "./tooth/Tooth25";
import Tooth26 from "./tooth/Tooth26";
import Tooth27 from "./tooth/Tooth27";
import Tooth47 from "./tooth/Tooth47";
import Tooth46 from "./tooth/Tooth46";
import Tooth45 from "./tooth/Tooth45";
import Tooth42 from "./tooth/Tooth42";
import Tooth41 from "./tooth/Tooth41";
import Tooth37 from "./tooth/Tooth37";
import Tooth36 from "./tooth/Tooth36";
import Tooth35 from "./tooth/Tooth35";
import Tooth32 from "./tooth/Tooth32";
import Tooth31 from "./tooth/Tooth31";
import { useTeethStore } from "../../zustand/teethStore";
import Tooth14 from "./tooth/Tooth14";
import Tooth13 from "./tooth/Tooth13";
import Tooth18 from "./tooth/Tooth18";
import Tooth23 from "./tooth/Tooth23";
import Tooth24 from "./tooth/Tooth24";
import Tooth28 from "./tooth/Tooth28";
import Tooth48 from "./tooth/Tooth48";
import Tooth44 from "./tooth/Tooth44";
import Tooth43 from "./tooth/Tooth43";
import Tooth33 from "./tooth/Tooth33";
import Tooth38 from "./tooth/Tooth38";
import Tooth34 from "./tooth/Tooth34";
import { toothDisplayNumberMap } from "./helper";

export default function MixDentationMouth() {
  const {
    visibleFindings,
    primaryTeeth,
    permanentTeeth,
  } = useTeethStore();

  const getFindingByTooth = useCallback(
    (tooth) =>
      visibleFindings.filter(
        (finding) => finding?.ToothNum === toothDisplayNumberMap[tooth]
      ) || "natural",
    [visibleFindings]
  );

  // Backside lower tooth diagram
  return (
    <Box
      className="MixDent"
      sx={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        maxWidth: "100%",
        margin: "auto",
        overflow: "auto",
        opacity: 0.8,
        zIndex: -99,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          maxWidth: "100%",
          margin: "auto",
          overflow: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
          gap={1.5}
        >
          <Tooth18
            finding={getFindingByTooth(18)}
            insideOverlay={true}
            primary={primaryTeeth.includes(18)}
            permanent={permanentTeeth?.includes(
              18
            )}
          />
          <Tooth17
            finding={getFindingByTooth(17)}
            insideOverlay={true}
            primary={primaryTeeth.includes(17)}
            permanent={permanentTeeth?.includes(
              17
            )}
          />
          <Tooth16
            finding={getFindingByTooth(16)}
            insideOverlay={true}
            primary={primaryTeeth.includes(16)}
            permanent={permanentTeeth?.includes(
              16
            )}
          />
          <Tooth15
            finding={getFindingByTooth(15)}
            insideOverlay={true}
            primary={primaryTeeth.includes(15)}
            permanent={permanentTeeth?.includes(
              15
            )}
          />
          <Tooth14
            finding={getFindingByTooth(14)}
            insideOverlay={true}
            primary={primaryTeeth.includes(14)}
            permanent={permanentTeeth?.includes(
              14
            )}
          />
          <Tooth13
            finding={getFindingByTooth(13)}
            insideOverlay={true}
            primary={primaryTeeth.includes(13)}
            permanent={permanentTeeth?.includes(
              13
            )}
          />
          <Tooth12
            finding={getFindingByTooth(12)}
            insideOverlay={true}
            primary={primaryTeeth.includes(12)}
            permanent={permanentTeeth?.includes(
              12
            )}
          />
          <Tooth11
            finding={getFindingByTooth(11)}
            insideOverlay={true}
            primary={primaryTeeth.includes(11)}
            permanent={permanentTeeth?.includes(
              11
            )}
          />
          <Tooth21
            finding={getFindingByTooth(21)}
            insideOverlay={true}
            primary={primaryTeeth.includes(21)}
            permanent={permanentTeeth?.includes(
              21
            )}
          />
          <Tooth22
            finding={getFindingByTooth(22)}
            insideOverlay={true}
            primary={primaryTeeth.includes(22)}
            permanent={permanentTeeth?.includes(
              22
            )}
          />
          <Tooth23
            finding={getFindingByTooth(23)}
            insideOverlay={true}
            primary={primaryTeeth.includes(23)}
            permanent={permanentTeeth?.includes(
              23
            )}
          />
          <Tooth24
            finding={getFindingByTooth(24)}
            insideOverlay={true}
            primary={primaryTeeth.includes(24)}
            permanent={permanentTeeth?.includes(
              24
            )}
          />
          <Tooth25
            finding={getFindingByTooth(25)}
            insideOverlay={true}
            primary={primaryTeeth.includes(25)}
            permanent={permanentTeeth?.includes(
              25
            )}
          />
          <Tooth26
            finding={getFindingByTooth(26)}
            insideOverlay={true}
            primary={primaryTeeth.includes(26)}
            permanent={permanentTeeth?.includes(
              26
            )}
          />
          <Tooth27
            finding={getFindingByTooth(27)}
            insideOverlay={true}
            primary={primaryTeeth.includes(27)}
            permanent={permanentTeeth?.includes(
              27
            )}
          />
          <Tooth28
            finding={getFindingByTooth(28)}
            insideOverlay={true}
            primary={primaryTeeth.includes(28)}
            permanent={permanentTeeth?.includes(
              28
            )}
          />
        </Stack>

        <Divider sx={{ my: 2.5 }} />

        <Stack
          direction="row"
          justifyContent="center"
          gap={1.5}
          pb={{ xs: 3, md: 8 }}
        >
          <Tooth48
            finding={getFindingByTooth(48)}
            insideOverlay={true}
            primary={primaryTeeth.includes(48)}
            permanent={permanentTeeth?.includes(
              48
            )}
          />
          <Tooth47
            finding={getFindingByTooth(47)}
            insideOverlay={true}
            primary={primaryTeeth.includes(47)}
            permanent={permanentTeeth?.includes(
              47
            )}
          />
          <Tooth46
            finding={getFindingByTooth(46)}
            insideOverlay={true}
            primary={primaryTeeth.includes(46)}
            permanent={permanentTeeth?.includes(
              46
            )}
          />
          <Tooth45
            finding={getFindingByTooth(45)}
            insideOverlay={true}
            primary={primaryTeeth.includes(45)}
            permanent={permanentTeeth?.includes(
              45
            )}
          />
          <Tooth44
            finding={getFindingByTooth(44)}
            insideOverlay={true}
            primary={primaryTeeth.includes(44)}
            permanent={permanentTeeth?.includes(
              44
            )}
          />
          <Tooth43
            finding={getFindingByTooth(43)}
            insideOverlay={true}
            primary={primaryTeeth.includes(43)}
            permanent={permanentTeeth?.includes(
              43
            )}
          />
          <Tooth42
            finding={getFindingByTooth(42)}
            insideOverlay={true}
            primary={primaryTeeth.includes(42)}
            permanent={permanentTeeth?.includes(
              42
            )}
          />
          <Tooth41
            finding={getFindingByTooth(41)}
            insideOverlay={true}
            primary={primaryTeeth.includes(41)}
            permanent={permanentTeeth?.includes(
              41
            )}
          />
          <Tooth31
            finding={getFindingByTooth(31)}
            insideOverlay={true}
            primary={primaryTeeth.includes(31)}
            permanent={permanentTeeth?.includes(
              31
            )}
          />
          <Tooth32
            finding={getFindingByTooth(32)}
            insideOverlay={true}
            primary={primaryTeeth.includes(32)}
            permanent={permanentTeeth?.includes(
              32
            )}
          />
          <Tooth33
            finding={getFindingByTooth(33)}
            insideOverlay={true}
            primary={primaryTeeth.includes(33)}
            permanent={permanentTeeth?.includes(
              33
            )}
          />
          <Tooth34
            finding={getFindingByTooth(34)}
            insideOverlay={true}
            primary={primaryTeeth.includes(34)}
            permanent={permanentTeeth?.includes(
              34
            )}
          />
          <Tooth35
            finding={getFindingByTooth(35)}
            insideOverlay={true}
            primary={primaryTeeth.includes(35)}
            permanent={permanentTeeth?.includes(
              35
            )}
          />
          <Tooth36
            finding={getFindingByTooth(36)}
            insideOverlay={true}
            primary={primaryTeeth.includes(36)}
            permanent={permanentTeeth?.includes(
              36
            )}
          />
          <Tooth37
            finding={getFindingByTooth(37)}
            insideOverlay={true}
            primary={primaryTeeth.includes(37)}
            permanent={permanentTeeth?.includes(
              37
            )}
          />
          <Tooth38
            finding={getFindingByTooth(38)}
            insideOverlay={true}
            primary={primaryTeeth.includes(38)}
            permanent={permanentTeeth?.includes(
              38
            )}
          />
        </Stack>
        {/* <Stack
          direction='row'
          justifyContent='center'
          gap={1.5}
          className='mixed-jaw'
        >
          <Tooth17
            finding={getFindingByTooth('A')}
            toothNumber={'A'} primary={primaryTeeth.includes('A)}
            overlayTooth
          />
          <Tooth16
            finding={getFindingByTooth('B')}
            toothNumber={'B'} primary={primaryTeeth.includes('B)}
            overlayTooth
          />
          <Tooth15
            finding={getFindingByTooth('C')}
            toothNumber={'C'} primary={primaryTeeth.includes('C)}
            overlayTooth
          />
          <Tooth12
            finding={getFindingByTooth('D')}
            toothNumber={'D'} primary={primaryTeeth.includes('D)}
            overlayTooth
          />
          <Tooth11
            finding={getFindingByTooth('E')}
            toothNumber={'E'} primary={primaryTeeth.includes('E)}
            overlayTooth
          />
          <Tooth21
            finding={getFindingByTooth('F')}
            toothNumber={'F'} primary={primaryTeeth.includes('F)}
            overlayTooth
          />
          <Tooth22
            finding={getFindingByTooth('G')}
            toothNumber={'G'} primary={primaryTeeth.includes('G)}
            overlayTooth
          />
          <Tooth25
            finding={getFindingByTooth('H')}
            toothNumber={'H'} primary={primaryTeeth.includes('H)}
            overlayTooth
          />
          <Tooth26
            finding={getFindingByTooth('I')}
            toothNumber={'I'} primary={primaryTeeth.includes('I)}
            overlayTooth
          />
          <Tooth27
            finding={getFindingByTooth('J')}
            toothNumber={'J'} primary={primaryTeeth.includes('J)}
            overlayTooth
          />
        </Stack>

        <Divider sx={{ my: 3 }} />

        <Stack
          direction='row'
          justifyContent='center'
          gap={1.5}
          pb={{ xs: 3, md: 8 }}
        >
          <Tooth47
            finding={getFindingByTooth('T')}
            toothNumber={'T'} primary={primaryTeeth.includes('T)}
            overlayTooth
          />
          <Tooth46
            finding={getFindingByTooth('S')}
            toothNumber={'S'} primary={primaryTeeth.includes('S)}
            overlayTooth
          />
          <Tooth45
            finding={getFindingByTooth('R')}
            toothNumber={'R'} primary={primaryTeeth.includes('R)}
            overlayTooth
          />
          <Tooth42
            finding={getFindingByTooth('Q')}
            toothNumber={'Q'} primary={primaryTeeth.includes('Q)}
            overlayTooth
          />
          <Tooth41
            finding={getFindingByTooth('P')}
            toothNumber={'P'} primary={primaryTeeth.includes('P)}
            overlayTooth
          />
          <Tooth31
            finding={getFindingByTooth('O')}
            toothNumber={'O'} primary={primaryTeeth.includes('O)}
            overlayTooth
          />
          <Tooth32
            finding={getFindingByTooth('N')}
            toothNumber={'N'} primary={primaryTeeth.includes('N)}
            overlayTooth
          />
          <Tooth35
            finding={getFindingByTooth('M')}
            toothNumber={'M'} primary={primaryTeeth.includes('M)}
            overlayTooth
          />
          <Tooth36
            finding={getFindingByTooth('L')}
            toothNumber={'L'} primary={primaryTeeth.includes('L)}
            overlayTooth
          />
          <Tooth37
            finding={getFindingByTooth('K')}
            toothNumber={'K'} primary={primaryTeeth.includes('K)}
            overlayTooth
          />
        </Stack> */}
      </Box>
    </Box>
  );
}

import request from "./axios/request";

export const fetchFindings = async (
  patientNumber
) =>
  await request({
    method: "GET",
    url: `patcon/v1/toothchart/${patientNumber}`,
  });

export const fetchPriorities = async () =>
  await request({
    method: "GET",
    url: `patcon/v1/priority`,
  });

export const deleteProcedureLog = async (
  procNum
) =>
  await request({
    method: "POST",
    url: `patcon/v1/toothchart/delete/procedurelog/${procNum}`,
  });

export const insertProcedureLog = async (body) =>
  await request({
    method: "POST",
    url: `patcon/v1/toothchart/insert/procedurelog`,
    data: JSON.stringify(body),
  });

export const insertMissingTooth = async (body) =>
  await request({
    method: "POST",
    url: `patcon/v1/toothinitial/insert/missing`,
    data: JSON.stringify(body),
  });

export const fetchMissingTeeth = async (
  patientNumber
) =>
  await request({
    method: "GET",
    url: `patcon/v1/toothinitial/${patientNumber}`,
  });

export const setToothInitial = async (
  patientNumber
) =>
    await request({
    method: "GET",
    url: `patcon/v1/toothinitial/set/${patientNumber}`,
  });

export const fetchProcCode = async () =>
  await request({
    method: "GET",
    url: `patcon/v1/toothchart/get/procedurecode`,
  });

export const fetchPerioExamMeasures = async (
  perioExamNum
) =>
  await request({
    method: "GET",
    url: `patcon/v1/periochart/exam/measure/list/${perioExamNum}`,
  });

export const fetchPerioExamList = async (
  patNum
) =>
  await request({
    method: "GET",
    url: `patcon/v1/periochart/exam/list/${patNum}`,
  });

export const fetchToothChartGraphicColor =
  async () =>
    await request({
      method: "GET",
      url: `patcon/v1/toothchart/get/chart/graphic/colors`,
    });

export const updateAllPrimary = async (
  patientNumber
) =>
  await request({
    method: "POST",
    url: `/patcon/v1/toothchart/${patientNumber}/set/all/prim`,
  });

export const updateAllPermanent = async (
  patientNumber
) =>
  await request({
    method: "POST",
    url: `/patcon/v1/toothchart/${patientNumber}/set/all/perm`,
  });

export const updateAllMixedDentition = async (
  patientNumber
) =>
  await request({
    method: "POST",
    url: `/patcon/v1/toothchart/${patientNumber}/set/all/mixed`,
  });

export const updateSelectedPrimary = async (
  patientNumber,
  toothNumber
) =>
  await request({
    method: "POST",
    url: `/patcon/v1/toothchart/${patientNumber}/set/sel/prim/${toothNumber}`,
  });

export const updateSelectedPermanent = async (
  patientNumber,
  toothNumber
) =>
  await request({
    method: "POST",
    url: `/patcon/v1/toothchart/${patientNumber}/set/sel/perm/${toothNumber}`,
  });

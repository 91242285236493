export const sortArrayData = (arrayToSort) =>
  arrayToSort?.sort((first, second) => {
    // TODO: add conditions for required data types
    if (isNaN(first?.ItemName) || isNaN(second?.ItemName)) {
      return first?.ItemName?.localeCompare(second?.ItemName);
    }

    return Number(first?.ItemName) - Number(second?.ItemName);
  });

export function intToHexColor(colorCode) {
  // Ensure the input is a valid integer
  if (isNaN(colorCode) || colorCode < 0 || colorCode > 16777215) {
    // throw new Error('Invalid color code');
  }
  // Convert the integer to a hex string
  var hexString = colorCode.toString(16).toUpperCase();
  // Pad the hex string with zeros to ensure it has six digits
  while (hexString.length < 6) {
    hexString = '0' + hexString;
  }
  // Add a hash symbol (#) at the beginning
  return '#' + hexString;
}

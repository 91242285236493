import { Close } from "@mui/icons-material";
import { Box, IconButton, Drawer as MuiDrawer, styled } from "@mui/material";
import React from "react";
import { slideMapping } from "./styles";
import { Stack } from "@mui/material";

const DrawerWrapper = styled(Box)(() => ({
  position: "relative",
  height: "100%",
  width: "100%",
}));

const DrawerBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  backgroundColor: theme.palette.common.white,
  zIndex: 9999,
  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.5)",
  overflow: "auto",
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "20px",
  right: "20px",
  border: "1px solid",
  borderColor: theme.palette.grey[400],
}));

export default function Drawer({
  open,
  anchor = "right",
  onClose,
  children,
  width,
  maxWidth,
  id = "drawer",
}) {
  const styles = {
    right: {
      bottom: 0,
      top: 0,
      right: 0,
      width: width || "400px",
      mx: "auto",
      maxWidth: maxWidth || "500px",
    },
    bottom: {
      bottom: 0,
      width: "100%",
      mx: "auto",
    },
  };

  return (
    <DrawerWrapper>
      <MuiDrawer
        open={open}
        mountOnEnter
        unmountOnExit
        direction={slideMapping[anchor]}
        id={id}
      >
        <DrawerBox sx={styles[anchor]}>
          <CloseButton onClick={onClose}>
            <Close />
          </CloseButton>
          <div>{children}</div>
        </DrawerBox>
      </MuiDrawer>
    </DrawerWrapper>
  );
}

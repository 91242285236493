import { Box, Button, Stack } from "@mui/material";
import React, { useEffect } from "react";
import Mouth from "../Mouth";
import { useTeethStore } from "../../../zustand/teethStore";
import AddTreatmentForm from "../../AddTreatmentForm";
import { TeethChartsTab } from "../common/TeethChartsTab";
import { primaryToothMap } from "../helper";
import {
  updateSelectedPermanent,
  updateSelectedPrimary,
} from "../../../api/findings";

const getPrimaryMappedArray = (inputArray) => {
  return inputArray.map((item) => {
    if (primaryToothMap[item]) {
      return primaryToothMap[item];
    }
    return item;
  });
};

export default function Edit() {
  const {
    allFindings,
    setActiveDateFindings,
    setEditTreatment,
    setVisibleFindings,
    activeTeeth,
    setPermanantTeeth,
    setPrimaryTeeth,
    setActiveTeeth,
    primaryTeeth,
    permanentTeeth,
    credentials,
  } = useTeethStore();

  useEffect(() => {
    const sortedFindings = [...allFindings].sort((first, second) =>
      second?.DateTStamp?.localeCompare(first?.DateTStamp),
    );
    setActiveDateFindings(sortedFindings);
    setVisibleFindings(sortedFindings);
    setEditTreatment(true);

    return () => {
      setEditTreatment(false);
    };
  }, [
    allFindings,
    setActiveDateFindings,
    setEditTreatment,
    setVisibleFindings,
  ]);

  const mappedActiveTeeth = getPrimaryMappedArray(activeTeeth);
  return (
    <>
      <Stack flexWrap={"wrap"}>
        {/* <AddTreatmentForm /> */}
        <Stack direction="row" alignItems={"center"}>
          <TeethChartsTab />
          <Stack
            direction="row"
            alignItems={"center"}
            sx={{ mb: "24px", ml: "24px" }}
          >
            {activeTeeth.length > 0 && (
              <>
                <Button
                  onClick={async () => {
                    if (
                      mappedActiveTeeth.some((teeth) =>
                        primaryTeeth.includes(teeth),
                      )
                    ) {
                      const newPrimaryTooth = [...primaryTeeth].filter(
                        (tooth) => !mappedActiveTeeth.includes(tooth),
                      );
                      setPrimaryTeeth(newPrimaryTooth);
                    }

                    console.log(permanentTeeth, mappedActiveTeeth);
                    setPermanantTeeth([
                      ...permanentTeeth,
                      ...mappedActiveTeeth,
                    ]);

                    updateSelectedPermanent(
                      credentials.patientNumber,
                      mappedActiveTeeth.join(","),
                    );
                    setActiveTeeth([]);
                  }}
                >
                  Set Selected Permanent
                </Button>
                <Button
                  onClick={() => {
                    if (
                      mappedActiveTeeth.some((teeth) =>
                        permanentTeeth.includes(teeth),
                      )
                    ) {
                      const newPermanentTooth = [...permanentTeeth].filter(
                        (tooth) => !mappedActiveTeeth.includes(tooth),
                      );
                      setPermanantTeeth(newPermanentTooth);
                    }
                    setPrimaryTeeth([...primaryTeeth, ...mappedActiveTeeth]);
                    updateSelectedPrimary(
                      credentials.patientNumber,
                      mappedActiveTeeth.join(","),
                    );

                    setActiveTeeth([]);
                  }}
                >
                  Set Selected Primamry
                </Button>
                <Button
                  onClick={() => {
                    setActiveTeeth([]);
                  }}
                >
                  Clear Selection
                </Button>
              </>
            )}
          </Stack>
        </Stack>

        <Box>
          <Mouth />
        </Box>
      </Stack>
    </>
  );
}

import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const ToothNumber = styled((props) => (
  <Typography
    variant='caption'
    textAlign='center'
    {...props}
  />
))(({ bgColor, textColor, mixDentition }) => ({
  marginBlock: mixDentition ? 0 : '8px',
  marginInline: 'auto',
  padding: '4px',
  lineHeight: '16px',
  display: 'block',
  height: '24px',
  width: '24px',
  boxSizing: 'border-box',
  backgroundColor: bgColor || 'transparent',
  borderRadius: '100%',
  color: textColor || 'initial',
  zIndex: mixDentition ? 1000 : 'unset',
}));

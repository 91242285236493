import { Stack, styled } from "@mui/material";

export const StyledToothWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== "isActiveTooth" && prop !== "lowerJaw"
})(({ isActiveTooth, lowerJaw, theme, insideOverlay, isToothHidden }) => ({
  position: "relative",

  // Tooth Select Icon
  "::after": {
    content: `url("data:image/svg+xml;charset=utf-8,%3Csvg fill='%23fff' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.6 9.5c-.8-.7-.8-1.9-.1-2.7l.1-.1c.8-.7 2.1-.7 2.9 0l2.4 2.4 6.7-6.5c.8-.8 2-.8 2.8 0 .8.8.8 2 0 2.8L7 13.6c-.6.6-1.6.6-2.2 0L.6 9.5z'/%3E%3C/svg%3E")`,
    position: "absolute",
    top: lowerJaw
      ? insideOverlay ? "80%" : "55%"
      : insideOverlay ? "20%" : "45%",
    left: "50%",
    transform: lowerJaw
      ? insideOverlay ? "translate(-50%, -80%)" : "translate(-50%, -55%)"
      : insideOverlay ? "translate(-50%, -20%)" : "translate(-50%, -45%)",
    zIndex: 1,
    height: "20px",
    width: "20px",
    visibility: isActiveTooth && !isToothHidden ? "visible" : "hidden",
    border: "2px solid #fff",
    padding: "3px",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    fontSize: "10px",
    lineHeight: "10px",
    overflow: "hidden",
    boxSizing: "border-box"
  },

  ":hover": {
    cursor: "pointer",
    "& img": {
      filter: isActiveTooth
        ? "none"
        : "hue-rotate(250deg) brightness(95%) contrast(100%) !important"
    }
  }
}));

export const upperJawTeeth = [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28];

export const lowerJawTeeth = [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38];

export const teeth = [...upperJawTeeth, ...lowerJawTeeth];

export const upperJawLeftOverlayTeeth = ['A', 'B', 'C', 'D', 'E'];

export const upperJawRightOverlayTeeth = ['F', 'G', 'H', 'I', 'J'];

export const lowerJawRightOverlayTeeth = ['K', 'L', 'M', 'N', 'O'];

export const lowerJawLeftOverlayTeeth = ['P', 'Q', 'R', 'S', 'T'];

export const upperJawOverlayTeeth = [...upperJawLeftOverlayTeeth, ...upperJawRightOverlayTeeth];
export const lowerJawOverlayTeeth = [...lowerJawLeftOverlayTeeth, ...lowerJawRightOverlayTeeth];

export const overlayTeeth = [...upperJawOverlayTeeth, ...lowerJawOverlayTeeth];

export const symetricTeeth = {
  11: 11,
  12: 12,
  13: 13,
  14: 14,
  15: 15,
  16: 16,
  17: 17,
  18: 18,
  21: 11,
  22: 12,
  23: 13,
  24: 14,
  25: 15,
  26: 16,
  27: 17,
  28: 18,
  31: 41,
  32: 42,
  33: 43,
  34: 44,
  35: 45,
  36: 46,
  37: 47,
  38: 48,
  41: 41,
  42: 42,
  43: 43,
  44: 44,
  45: 45,
  46: 46,
  47: 47,
  48: 48,
};

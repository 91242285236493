/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import '../../components/TeethDiagram/index.css';
import { LineGraph } from '../Graph/Line';
import { Box, Stack } from '@mui/system';
import { CircularProgress, Divider } from '@mui/material';
import ToothLargerView from '../TeethDiagram/ToothLargerView';
import { getToothLargeViewProps } from '../TeethDiagram/toothLargeViewProps';
import { symetricTeeth } from '../../constants/teeth/tooth';
import { fetchPerioExamMeasures } from '../../api/findings';
import { getToothDisplayNumber } from '../TeethDiagram/helper';
import { useSnackbar } from 'notistack';
import { xAxisPaddings } from './graphStyles';

const toothLargeviewProps = getToothLargeViewProps();

export default function ViewPerioGraph({ perioExamNum }) {
  const [isCallingApi, setApiCalling] = useState(false);
  const [graphHeight, setGraphHeight] = useState(0);
  const [graphData, setGraphData] = useState({});
  const [examDate, setExamDate] = useState('2023-11-22');
  const { enqueueSnackbar } = useSnackbar();

  const callfetchPerioExamApi = async () => {
    if (!isCallingApi) {
      setApiCalling(true);
      try {
        const response = await fetchPerioExamMeasures(perioExamNum);
        const newGraphData = {};
        response
          .filter((data) => Number(data.SequenceType) === 4)
          .forEach((data) => {
            const isRightSide = data.IntTooth >= 9 && data.IntTooth <= 24;
            const bValues = [
              {
                name: 'Perio',
                value: isRightSide ? data.MBvalue : data.DBvalue,
              },
              {
                name: 'Perio',
                value: data.Bvalue,
              },
              {
                name: 'Perio',
                value: isRightSide ? data.DBvalue : data.MBvalue,
              },
            ];

            const lValues = [
              {
                name: 'Perio',
                value: isRightSide ? data.MLvalue : data.DLvalue,
              },
              {
                name: 'Perio',
                value: data.Lvalue,
              },
              {
                name: 'Perio',
                value: isRightSide ? data.DLvalue : data.MLvalue,
              },
            ];

            // Upper jaw
            if (data.IntTooth >= 1 && data.IntTooth <= 16) {
              newGraphData[data.IntTooth] = {
                upper: bValues,
                lower: lValues,
              };
            } else {
              // Lower jaw
              newGraphData[data.IntTooth] = {
                upper: lValues,
                lower: bValues,
              };
            }
          });
        setGraphData(newGraphData);
      } catch (error) {
        console.log({ error });
        enqueueSnackbar({
          message: 'Failed to fetch Procedure codes!',
          variant: 'error',
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
        });
      }

      setApiCalling(false);
    }
  };

  useEffect(() => {
    if (perioExamNum) {
      callfetchPerioExamApi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perioExamNum]);

  const onImageLoaded = (activeTeeth) => {
    const activeTooth = document.getElementById(`root-${activeTeeth}`);

    if (activeTooth) {
      const newGraphHeight = activeTooth?.getBoundingClientRect()?.height;
      if (newGraphHeight !== graphHeight) setGraphHeight(newGraphHeight);
    }
  };

  return (
    <>
      {isCallingApi ? (
        <Stack
          height='100%'
          width='100%'
          alignItems='center'
          justifyContent='center'
        >
          <CircularProgress />
        </Stack>
      ) : (
        <Box
          sx={{
            width: '80.27vh',
            position: 'relative',
            minWidth: '810px',
            maxWidth: '1180px',
            margin: 'auto',
            padding: { xs: '24px 16px', md: '40px 72px' },
          }}
        >
          <Stack
            direction='row'
            justifyContent='center'
            position='relative'
            height='100%'
            width='100%'
          >
            {[18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28].map((activeTeeth) => (
              <Box
                key={activeTeeth}
                sx={{ position: 'relative' }}
                className={`tooth-container-${symetricTeeth[activeTeeth]}`}
              >
                <ToothLargerView
                  onRootLoad={() => onImageLoaded(activeTeeth)}
                  {...toothLargeviewProps[activeTeeth]}
                  tooth={activeTeeth}
                  toothImageWrapperClassName={`tooth-${activeTeeth}`}
                  toothSx={{
                    width: '100%',
                    minWidth: 'unset',
                    visibility: graphData?.[getToothDisplayNumber(activeTeeth)]?.upper ? 'visible' : 'hidden',
                  }}
                  canSelectCrownParts={false}
                  showCrownParts={false}
                />
                <Box
                  sx={{ position: 'absolute', top: '22%' }}
                  width='100%'
                  height={`${graphHeight - 80}px`}
                >
                  <LineGraph
                    data={graphData?.[getToothDisplayNumber(activeTeeth)]?.upper}
                    xAxisPadding={xAxisPaddings[activeTeeth]}
                  />
                </Box>
              </Box>
            ))}
          </Stack>

          <Stack
            direction='row'
            justifyContent='center'
          >
            {[18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28].map((activeTeeth) => (
              <Stack
                width='100%'
                height='50px'
                justifyContent='center'
                alignItems='center'
                key={activeTeeth}
                className={`tooth-container-${symetricTeeth[activeTeeth]}`}
              >
                {getToothDisplayNumber(activeTeeth)}
              </Stack>
            ))}
          </Stack>

          <Stack
            direction='row'
            justifyContent='center'
            position='relative'
            height='100%'
            width='100%'
          >
            {[18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28].map((activeTeeth) => (
              <Box
                key={activeTeeth}
                sx={{ position: 'relative' }}
                className={`tooth-container-${symetricTeeth[activeTeeth]}`}
              >
                <ToothLargerView
                  onRootLoad={() => onImageLoaded(activeTeeth)}
                  {...toothLargeviewProps[activeTeeth]}
                  tooth={activeTeeth}
                  toothImageWrapperClassName={`tooth-${activeTeeth}`}
                  toothSx={{
                    width: '100%',
                    minWidth: 'unset',
                    visibility: graphData?.[getToothDisplayNumber(activeTeeth)]?.upper ? 'visible' : 'hidden',
                  }}
                  canSelectCrownParts={false}
                  showCrownParts={false}
                />
                <Box
                  sx={{ position: 'absolute', top: '22%' }}
                  width='100%'
                  height={`${graphHeight - 80}px`}
                >
                  <LineGraph
                    data={graphData?.[getToothDisplayNumber(activeTeeth)]?.lower}
                    xAxisPadding={xAxisPaddings[activeTeeth]}
                  />
                </Box>
              </Box>
            ))}
          </Stack>

          <Divider sx={{ my: 2 }} />

          <Stack
            direction='row'
            justifyContent='center'
            position='relative'
            height='100%'
            width='100%'
          >
            {[48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38].map((activeTeeth) => (
              <Box
                key={activeTeeth}
                sx={{ position: 'relative' }}
                className={`tooth-container-${symetricTeeth[activeTeeth]}`}
              >
                <ToothLargerView
                  onRootLoad={() => onImageLoaded(activeTeeth)}
                  {...toothLargeviewProps[activeTeeth]}
                  tooth={activeTeeth}
                  toothImageWrapperClassName={`tooth-${activeTeeth}`}
                  toothSx={{
                    width: '100%',
                    minWidth: 'unset',
                    visibility: graphData?.[getToothDisplayNumber(activeTeeth)]?.upper ? 'visible' : 'hidden',
                  }}
                  canSelectCrownParts={false}
                  showCrownParts={false}
                />
                <Box
                  sx={{ position: 'absolute', bottom: '22%' }}
                  width='100%'
                  height={`${graphHeight - 80}px`}
                >
                  <LineGraph
                    reversed
                    data={graphData?.[getToothDisplayNumber(activeTeeth)]?.upper}
                    xAxisPadding={xAxisPaddings[activeTeeth]}
                  />
                </Box>
              </Box>
            ))}
          </Stack>

          <Stack
            direction='row'
            justifyContent='center'
          >
            {[48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38].map((activeTeeth) => (
              <Stack
                width='100%'
                height='50px'
                justifyContent='center'
                alignItems='center'
                key={activeTeeth}
                className={`tooth-container-${symetricTeeth[activeTeeth]}`}
              >
                {getToothDisplayNumber(activeTeeth)}
              </Stack>
            ))}
          </Stack>

          <Stack
            direction='row'
            justifyContent='center'
            position='relative'
            height='100%'
            width='100%'
          >
            {[48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38].map((activeTeeth) => (
              <Box
                key={activeTeeth}
                sx={{ position: 'relative' }}
                className={`tooth-container-${symetricTeeth[activeTeeth]}`}
              >
                <ToothLargerView
                  onRootLoad={() => onImageLoaded(activeTeeth)}
                  {...toothLargeviewProps[activeTeeth]}
                  tooth={activeTeeth}
                  toothImageWrapperClassName={`tooth-${activeTeeth}`}
                  toothSx={{
                    width: '100%',
                    minWidth: 'unset',
                    visibility: graphData?.[getToothDisplayNumber(activeTeeth)]?.upper ? 'visible' : 'hidden',
                  }}
                  canSelectCrownParts={false}
                  showCrownParts={false}
                />
                <Box
                  sx={{ position: 'absolute', bottom: '22%' }}
                  width='100%'
                  height={`${graphHeight - 80}px`}
                >
                  <LineGraph
                    reversed
                    data={graphData?.[getToothDisplayNumber(activeTeeth)]?.lower}
                    xAxisPadding={xAxisPaddings[activeTeeth]}
                  />
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}

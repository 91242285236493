import React, { useMemo, useState, useEffect } from "react";
import Drawer from "../Drawer";
import { Box, Button, Grid, Stack, Typography, TextField } from "@mui/material";
import { blue, grey } from "@mui/material/colors";
import { useTeethStore } from "../../zustand/teethStore";
import { ProcTableFilter } from "../TeethDiagram/ProcTableFilter";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { TeethChartsTab } from "../../components/TeethDiagram/common/TeethChartsTab";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: "white",
    fontWeight: 700,
    cursor: "pointer",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Filter({ open, onClose, clearFilter }) {
  const {
    allFindings,
    setAllFindings,
    activeDateFindings,
    visibleFindings,
    setVisibleFindings,
    canEditTreatment,
    indicatorColors,
    setApplyFiltersList,
    appliedFiltersList,
    setActiveTeethChartTab,
    setAllPermanent,
  } = useTeethStore();
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [fieldValues, setFieldValues] = useState([]);
  const [orderBy, setOrderBy] = useState("ProcDate");
  const [order, setOrder] = useState("asc");
  const tableHeaders = [
    {
      id: "ProcStatus",
      label: "STATUS",
      numeric: false,
      filter: true,
      filterProps: {
        field: {
          id: "05",
          label: "Status",
          value: "ProcStatus",
          type: "dropdown",
          options: [
            ...Object.values(
              useTeethStore.getState()?.indicatorColors || {},
            ).map((status) => ({
              label: status.description,
              value: status.code,
              color: status.color,
            })),
          ],
        },
      },
    },
    {
      id: "ProcDate",
      label: "DATE",
      numeric: false,
      date: true,
      filter: false,
    },
    {
      id: "ToothNum",
      label: "TOOTH",
      numeric: true,
      filter: true,
      filterProps: {
        field: {
          id: "02",
          label: "Tooth",
          value: "ToothNum",
          type: "number",
        },
      },
    },
    {
      id: "Surf",
      label: "SURFACES",
      numeric: false,
      filter: true,
      filterProps: {
        field: {
          id: "03",
          label: "Surfaces",
          value: "Surf",
          type: "string",
        },
      },
    },
    {
      id: "Descript",
      label: "DESCRIPTION",
      numeric: false,
      filter: true,
      filterProps: {
        field: {
          id: "04",
          label: "Description",
          value: "Descript",
          type: "string",
        },
      },
    },
    {
      id: "ProcCode",
      label: "CODE",
      numeric: false,
      filter: true,
      filterProps: {
        field: {
          id: "06",
          label: "Code",
          value: "ProcCode",
          type: "string",
        },
      },
    },
  ];

  useEffect(() => {
    clearFiltration();
    setActiveTeethChartTab(0);
    setAllPermanent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearFilter]);

  const filterAndSortRows = React.useCallback(
    (filterFields = appliedFiltersList) => {
      const filteredData = activeDateFindings.filter(
        (findingData) =>
          filterFields.every((filterFieldData) =>
            Array.isArray(filterFieldData.value) &&
            filterFieldData.value.length > 0
              ? filterFieldData.value.includes(findingData[filterFieldData.key])
              : Array.isArray(filterFieldData.value) &&
                filterFieldData.value.length === 0
              ? String(findingData[filterFieldData.key])
              : String(findingData[filterFieldData.key])?.includes(
                  filterFieldData.value,
                ),
          ) || findingData?.ProcStatus === indicatorColors[4].code,
      );

      setVisibleFindings(
        stableSort(filteredData, getComparator(order, orderBy)),
      );
    },
    [
      appliedFiltersList,
      activeDateFindings,
      setVisibleFindings,
      order,
      orderBy,
      indicatorColors,
    ],
  );

  const handleChangeFilter = (fields) => {
    filterAndSortRows(fields);
  };

  function handleFieldChange(key, value) {
    setFieldValues((prevFieldValues) => {
      const newFieldValues = prevFieldValues;
      newFieldValues[key] = value;
      return newFieldValues;
    });
  }

  const clearFiltration = () => {
    setFieldValues([]);
    setAppliedFilters([]);
    setApplyFiltersList([]);
  };

  return (
    <Drawer open={open} onClose={onClose} width="100%" maxWidth="500px">
      <Box sx={{ width: "100%", padding: 2 }}>
        <Typography pb={4} variant="h4">
          Filter
        </Typography>
        <TeethChartsTab />
        {tableHeaders.map((header) => {
          return (
            <ProcTableFilter
              onChangeFilter={handleChangeFilter}
              appliedFilters={appliedFiltersList}
              setAppliedFilters={setApplyFiltersList}
              handleFieldChange={handleFieldChange}
              field={header.filterProps?.field}
              label={header.label}
              fieldFilterValue={
                fieldValues[header.filterProps?.field?.value]
                  ? fieldValues[header.filterProps?.field?.value]
                  : header.filterProps?.field?.type === "dropdown"
                  ? []
                  : null
              }
              showLabel
              fullWidth
            />
          );
        })}
      </Box>
    </Drawer>
  );
}

import React, { useMemo } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { useTeethStore } from "../../zustand/teethStore";
import { Datepicker } from "../Mui/Datepicker";
import { FilledTab } from "../Mui/Tab/Filled";

export default function AddTreatmentForm() {
  const {
    priorities,
    firstProcDate,
    setTreatmentFormData,
    indicatorColors,
    treatmentFormData: { procStatus, procDate, priority },
  } = useTeethStore();

  const handlePriorityChange = (event) => {
    setTreatmentFormData({
      procDate,
      procStatus,
      priority: event.target.value,
    });
  };

  const handleProcStatusChange = (event, activeTab) => {
    setTreatmentFormData({
      procDate,
      priority,
      procStatus: activeTab,
    });
  };

  const procDateRange = useMemo(() => {
    switch (Number(procStatus)) {
      case 1:
        setTreatmentFormData({
          priority,
          procStatus,
          procDate: dayjs(new Date()),
        });
        return { disablePast: true };

      case 3:
        setTreatmentFormData({
          priority,
          procStatus,
          procDate: dayjs(new Date(firstProcDate)),
        });
        return { disabled: true };

      case 5:
        setTreatmentFormData({
          priority,
          procStatus,
          procDate: dayjs(new Date()),
        });
        return { disabled: true };

      default:
        return {
          max: null,
          min: null,
        };
    }
  }, [procStatus, setTreatmentFormData, priority, firstProcDate]);

  return (
    <>
      <Typography variant="h5" mb={3} pl={1}>
        Add Treatment
      </Typography>

      <FormControl sx={{ paddingLeft: "5px", paddingRight: "5px" }}>
        <FormLabel
          sx={{ fontSize: 18, paddingLeft: "5px" }}
          id="procStatusAriaLabel"
        >
          Procedure status
        </FormLabel>
        <FilledTab
          tabs={[
            {
              label: `${indicatorColors[1].title} - ${indicatorColors[1].description}`,
              value: 0,
            },
            {
              label: `${indicatorColors[3].title} - ${indicatorColors[3].description}`,
              value: 1,
            },
            {
              label: `${indicatorColors[5].title} - ${indicatorColors[5].description}`,
              value: 2,
            },
            {
              label: `${indicatorColors[4].title} - ${indicatorColors[4].description}`,
              value: 3,
            },
          ]}
          value={procStatus}
          onChange={handleProcStatusChange}
        />
      </FormControl>

      <Stack
        direction={"row"}
        gap={2}
        pl={1}
        alignItems="flex-end"
        mt={3}
        mb={4}
        pr={1}
      >
        <Datepicker
          label="Procedure date"
          value={procDate}
          disabled={!procStatus}
          onChange={(newValue) =>
            setTreatmentFormData({
              priority,
              procStatus,
              procDate: newValue,
            })
          }
          sx={{ "flex-direction": "column !important", width: "50%" }}
          {...procDateRange}
        />

        <FormControl size="small" sx={{ width: "50%" }}>
          <InputLabel id="prioritiesSelectHelperLable">Priority</InputLabel>
          <Select
            labelId="prioritiesSelectHelperLable"
            value={priority}
            label="Priority"
            onChange={handlePriorityChange}
          >
            {priorities?.map((priority) => (
              <MenuItem value={priority.DefNum}>{priority.ItemName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </>
  );
}

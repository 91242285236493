/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useTeethStore } from "../../zustand/teethStore";
import Finding from "../Finding";
import {
  fetchFindings,
  fetchMissingTeeth,
  fetchPriorities,
  fetchProcCode,
  fetchToothChartGraphicColor,
} from "../../api/findings";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import TreatmentInformation from "./TreatmentInformation";
import { apiCredentials } from "../../api/axios/config";
import { intToHexColor, sortArrayData } from "../../utils/helper";
import { createProcDate } from "./helper";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import Tabs from "../Mui/Tab";
import { View } from "./tabs/View";
import Edit from "./tabs/Edit";
import { useSnackbar } from "notistack";
import VisualizationIndicators from "./VisualizationIndicators";
import dayjs from "dayjs";
import Filter from "../Filter";

const tabItems = [
  {
    label: "View",
    content: View,
  },
  { label: "Edit", content: Edit },
];

export default function TeethDiagram() {
  const [showFinding, setShowFinding] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [isClearFilter, setIsClearFilter] = useState(false);

  const patNum = searchParams.get("patNum");
  const clinicNum = searchParams.get("clinicNum");
  const ProvNum = searchParams.get("ProvNum");
  const AptNum = searchParams.get("AptNum");
  const uniqueToken = searchParams.get("uniqueToken");

  const {
    activeTeeth,
    setAllFindings,
    allFindings,
    setPriorities,
    credentials,
    setProcCodes,
    setCredentials,
    setIndicatorColors,
    indicatorColors,
  } = useTeethStore();

  const showHideFilter = (value) => {
    setShowFilter(value);
  };

  useEffect(() => {
    if (patNum && clinicNum && ProvNum && AptNum && uniqueToken) {
      setCredentials({
        ...credentials,
        accessToken: uniqueToken,
        patientNumber: patNum,
        appointmentNum: AptNum,
        clinicNum: clinicNum,
        provNum: ProvNum,
      });

      searchParams.delete("patNum");
      searchParams.delete("clinicNum");
      searchParams.delete("ProvNum");
      searchParams.delete("AptNum");
      searchParams.delete("uniqueToken");

      setSearchParams(searchParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patNum, clinicNum, ProvNum, AptNum, uniqueToken]);

  useEffect(() => {
    if (activeTeeth.length) {
      setShowFinding(true);
    } else {
      setShowFinding(false);
    }
  }, [activeTeeth]);

  const callFetchFindingsApi = useCallback(async () => {
    try {
      if (credentials.patientNumber) {
        const res = await fetchFindings(credentials.patientNumber);
        const missingTeethRes = await fetchMissingTeeth(
          credentials.patientNumber,
        );

        const findings = [
          ...(res || [])
            ?.map((r) => ({
              ...r,
              ToothNum: isNaN(r?.ToothNum) ? r?.ToothNum : Number(r?.ToothNum),
            }))
            .sort((first, second) =>
              first?.DateTStamp?.localeCompare(second?.DateTStamp),
            ),
          ...(missingTeethRes || [])?.map((r) => ({
            ...r,
            ProcDate: createProcDate(r?.SecDateTEntry),
            ToothNum: isNaN(r?.ToothNum) ? r?.ToothNum : Number(r?.ToothNum),
            Missing: true,
            Descript: "Missing",
            DateTStamp: r?.SecDateTEdit,
          })),
        ];

        if (findings.length === 0) {
          enqueueSnackbar({
            message: "No records found",
            variant: "info",
            anchorOrigin: { horizontal: "right", vertical: "top" },
          });
        }

        setAllFindings(findings);
      } else {
        enqueueSnackbar({
          message: "No patient number available!",
          variant: "error",
          anchorOrigin: { horizontal: "right", vertical: "top" },
        });
      }
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to fetch procedures!",
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  }, [credentials, setAllFindings]);

  const callFetchPrioritiesApi = useCallback(async () => {
    try {
      const res = await fetchPriorities();

      setPriorities(sortArrayData(res));

      setShowContent(true);
    } catch (error) {
      return navigate(`/403?status=${error?.cause?.response?.status}`);
    }
  }, [setPriorities]);

  const callFetchProcCodeApi = useCallback(async () => {
    try {
      const res = await fetchProcCode();

      setProcCodes(res);
    } catch (error) {
      enqueueSnackbar({
        message: "Failed to fetch Procedure codes!",
        variant: "error",
        anchorOrigin: { horizontal: "right", vertical: "top" },
      });
    }
  }, [setProcCodes]);

  const callFetchToothChartColorsApi = async () => {
    try {
      const res = await fetchToothChartGraphicColor();
      const mappedIndicatorColors = { ...indicatorColors };

      res
        ?.filter((r) => r?.ItemName?.includes("(light)"))
        ?.forEach((r) => {
          mappedIndicatorColors[r.ItemOrder] = {
            ...(mappedIndicatorColors[r.ItemOrder] || {
              textColor: "white",
              description: r.ItemName.replace("(light)", ""),
              title: r.ItemName?.replace("(light)", "")
                ?.split(" ")
                ?.map((name) => name?.charAt(0))
                ?.join(""),
              code: r.ItemOrder,
            }),
            color: intToHexColor(Math.abs(r.ItemColor)),
          };
        });

      setIndicatorColors(mappedIndicatorColors);
    } catch (error) {
      return navigate(`/403?status=${error?.cause?.response?.status}`);
    }
  };

  const handleCredentialFormSubmit = () => {
    if (
      credentials.accessToken &&
      credentials.patientNumber &&
      credentials.appointmentNum &&
      credentials.clinicNum &&
      credentials.provNum
    ) {
      apiCredentials.accessToken = credentials.accessToken;
      callFetchFindingsApi();
      callFetchPrioritiesApi();
      callFetchProcCodeApi();
      callFetchToothChartColorsApi();
    }
  };

  const clearFilter = (value) => {
    setIsClearFilter(value);
  };

  useEffect(() => {
    handleCredentialFormSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credentials]);

  if (
    !(patNum && clinicNum && ProvNum && AptNum && uniqueToken) &&
    !(
      credentials.accessToken &&
      credentials.patientNumber &&
      credentials.appointmentNum &&
      credentials.clinicNum &&
      credentials.provNum
    )
  ) {
    return <Navigate to="/404" replace />;
  }

  return (
    <>
      {!showContent && (
        <Stack
          justifyContent="center"
          alignItems="center"
          width="98vw"
          height="90vh"
          position="absolute"
          zIndex={9999}
        >
          <CircularProgress />
        </Stack>
      )}

      {showContent && (
        <>
          <Box>
            <Stack
              direction="row"
              alignItems="center"
              gap="20px"
              justifyContent="center"
              pt={{ xs: 3, md: 5 }}
              mb="10px"
            >
              <Typography textAlign="center" variant="h5" color="#3cbbeb">
                Tooth Chart
              </Typography>

              <Button
                onClick={() => navigate("/perio")}
                sx={{
                  color: "#3cbbeb",
                  textTransform: "capitalize",
                  opacity: 0.4,
                  fontSize: "1.5rem",
                }}
              >
                Periodental
              </Button>
            </Stack>

            <Stack
              bgcolor="#3cbbeb"
              p="8px"
              justifyContent="center"
              alignItems="center"
              mb={{ xs: 2, md: 3 }}
            >
              <Typography color="#f1f1f1">
                {allFindings?.[0]?.FName} {allFindings?.[0]?.LName} (
                {dayjs(allFindings?.[0]?.Birthdate).format("MM/DD/YYYY")})
              </Typography>
            </Stack>
          </Box>
          <Box mx="auto" px={{ xs: "16px", md: "24px", lg: "72px" }}>
            <Stack gap="24px">
              <Tabs
                tabs={tabItems}
                showHideFilter={showHideFilter}
                clearFilter={clearFilter}
              />
              {/* {isLaptopScreen && <VisualizationIndicators />} */}
            </Stack>
          </Box>
        </>
      )}

      {showContent && <TreatmentInformation />}
      <Filter
        open={showFilter}
        onClose={() => showHideFilter(false)}
        clearFilter={isClearFilter}
      />
      <Finding
        open={showFinding}
        onHideFinding={() => setShowFinding(false)}
        onAddingFindingSuccess={callFetchFindingsApi}
      />
    </>
  );
}

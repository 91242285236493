import { Box, Stack, styled } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTeethStore } from "../../zustand/teethStore";
import ImageMap from "image-map";
import { lowerJawTeeth, symetricTeeth } from "../../constants/teeth/tooth";
import { getCrownAreaPartName } from "./helper";

const StyledToothImage = styled("img", {
  shouldForwardProp: (props) => props !== "right",
})(({ right }) => ({
  height: "100%",
  width: "100%",

  transform: right ? "scaleX(-1)" : "unset",
}));

export const StyledArea = styled("area", {
  shouldForwardProp: (props) => props !== "right",
})(({ right, canSelectCrownParts }) => ({
  transform: right ? "scaleX(-1)" : "unset",

  "&:hover": {
    cursor: canSelectCrownParts ? "pointer" : "default",
  },
}));

export const StyledCanvas = styled("canvas", {
  shouldForwardProp: (props) => props !== "right",
})(({ right }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: "none",
  transform: right ? "scaleX(-1)" : "unset",
}));

export default function ToothLargerView({
  tooth,
  toothImage,
  crownUpperviewImage,
  coords,
  right,
  onRootLoad = () => {},
  toothSx = {},
  canSelectCrownParts = true,
  showCrownParts = true,
}) {
  const { selectedCrownAreas, setCrownAreas, activeTeeth } = useTeethStore();
  const isLowerJawTooth = useMemo(() => lowerJawTeeth.includes(tooth), [tooth]);

  const handleCrownImageLoad = useCallback(() => {
    new ImageMap(`img[usemap="#crownUpperViewMapImageForm${tooth}"]`);

    const container = document.getElementById(
      `crownUpperviewContainerForm${tooth}`,
    );
    const { width, height } = container.getBoundingClientRect();

    const canvas = document.getElementById(
      `crownUpperViewMapImageCanvasForm${tooth}`,
    );
    const selectedCanvas = document.getElementById(
      `selectedCrownAreaImageCanvasForm${tooth}`,
    );

    canvas.height = height;
    canvas.width = width;

    selectedCanvas.height = height;
    selectedCanvas.width = width;
  }, [tooth]);

  const fillColorInCanvas = useCallback(
    (coord, canvasId, area) => {
      const coords = coord.split(",");

      const canvas = document.getElementById(
        canvasId || `crownUpperViewMapImageCanvasForm${tooth}`,
      );
      const ctx = canvas.getContext("2d");

      ctx.fillStyle = "rgba(255, 0, 0, .5)";

      ctx.beginPath();

      if (coords.length === 3) {
        ctx.arc(coords[0], coords[1], coords[2], 0, 2 * Math.PI, false);
      } else {
        ctx.moveTo(coords[0], coords[1]);

        for (let item = 2; item < coords.length - 1; item += 2) {
          ctx.lineTo(coords[item], coords[item + 1]);
        }
      }

      ctx.closePath();
      ctx.fill();
    },
    [tooth],
  );

  const handleAreaMouseEnter = useCallback(
    (event, canvasId, area) => {
      if (canSelectCrownParts) {
        fillColorInCanvas(event.target.coords, canvasId, area);
      }
    },
    [canSelectCrownParts, fillColorInCanvas],
  );

  const handleAreaMouseLeave = useCallback(
    (_event, canvasId) => {
      if (canSelectCrownParts) {
        const container = document.getElementById(
          `crownUpperviewContainerForm${tooth}`,
        );
        const { width, height } = container.getBoundingClientRect();
        const canvas = document.getElementById(
          canvasId || `crownUpperViewMapImageCanvasForm${tooth}`,
        );
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, width, height);
      }
    },
    [canSelectCrownParts, tooth],
  );

  const handleAreaClick = (event, area) => {
    if (canSelectCrownParts) {
      const crownAreas = [...selectedCrownAreas];
      const areaIndex = crownAreas.findIndex(
        (crownArea) => crownArea?.area === `${tooth}-${area}`,
      );

      if (areaIndex !== -1) {
        crownAreas.splice(areaIndex, 1);
      } else {
        crownAreas.push({
          area: `${tooth}-${area}`,
          coords: event.target.coords,
        });
      }

      handleAreaMouseLeave(event, `selectedCrownAreaImageCanvasForm${tooth}`);

      crownAreas.forEach((area) => {
        if (area.area?.startsWith(tooth)) {
          fillColorInCanvas(
            area.coords,
            `selectedCrownAreaImageCanvasForm${tooth}`,
          );
        }
      });

      setCrownAreas(crownAreas);
    }
  };

  useEffect(() => {
    if (showCrownParts) {
      handleCrownImageLoad();
    }
  }, [activeTeeth, handleCrownImageLoad, showCrownParts]);

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      className={`tooth-container-large-${symetricTeeth[tooth]}`}
      sx={{ ...toothSx }}
    >
      <Stack>
        {!isLowerJawTooth && (
          <StyledToothImage
            onLoad={onRootLoad}
            id={`root-${tooth}`}
            src={toothImage}
            right={right}
            className={`tooth-${tooth}`}
            sx={{ maxHeight: "235px" }}
          />
        )}

        {showCrownParts && (
          <Box position="relative" id={`crownUpperviewContainerForm${tooth}`}>
            <StyledToothImage
              alt=""
              src={crownUpperviewImage}
              useMap={`#crownUpperViewMapImageForm${tooth}`}
              right={right}
              onLoad={handleCrownImageLoad}
              className={`crown-area-${tooth}`}
            />

            <StyledCanvas
              id={`crownUpperViewMapImageCanvasForm${tooth}`}
              right={right}
            />

            <StyledCanvas
              id={`selectedCrownAreaImageCanvasForm${tooth}`}
              right={right}
            />

            <map
              name={`crownUpperViewMapImageForm${tooth}`}
              id={`crownUpperViewMapImageForm${tooth}`}
            >
              {/* Center */}
              <StyledArea
                alt=""
                title=""
                coords={coords?.center}
                shape="circle"
                right={right}
                onClick={(event) =>
                  handleAreaClick(event, getCrownAreaPartName("center", tooth))
                }
                onMouseEnter={handleAreaMouseEnter}
                onMouseLeave={handleAreaMouseLeave}
                canSelectCrownParts={canSelectCrownParts}
              />
              {/* Bottom */}
              <StyledArea
                alt=""
                title=""
                coords={coords?.bottom}
                shape="poly"
                right={right}
                onMouseEnter={handleAreaMouseEnter}
                onClick={(event) =>
                  handleAreaClick(event, getCrownAreaPartName("bottom", tooth))
                }
                onMouseLeave={handleAreaMouseLeave}
                canSelectCrownParts={canSelectCrownParts}
              />
              {/* Right */}
              <StyledArea
                alt=""
                title=""
                coords={coords?.right}
                shape="poly"
                right={right}
                onMouseEnter={handleAreaMouseEnter}
                onClick={(event) =>
                  handleAreaClick(event, getCrownAreaPartName("right", tooth))
                }
                onMouseLeave={handleAreaMouseLeave}
                canSelectCrownParts={canSelectCrownParts}
              />
              {/* Top */}
              <StyledArea
                alt=""
                title=""
                coords={coords?.top}
                shape="poly"
                right={right}
                onMouseEnter={handleAreaMouseEnter}
                onClick={(event) =>
                  handleAreaClick(event, getCrownAreaPartName("top", tooth))
                }
                onMouseLeave={handleAreaMouseLeave}
                canSelectCrownParts={canSelectCrownParts}
              />
              {/* Left */}
              <StyledArea
                alt=""
                title=""
                coords={coords?.left}
                shape="poly"
                right={right}
                onMouseEnter={handleAreaMouseEnter}
                onClick={(event) =>
                  handleAreaClick(event, getCrownAreaPartName("left", tooth))
                }
                onMouseLeave={handleAreaMouseLeave}
                canSelectCrownParts={canSelectCrownParts}
              />
            </map>
          </Box>
        )}

        {isLowerJawTooth && (
          <StyledToothImage
            onLoad={onRootLoad}
            id={`root-${tooth}`}
            src={toothImage}
            right={right}
            sx={{ maxHeight: "235px" }}
          />
        )}
      </Stack>
    </Stack>
  );
}

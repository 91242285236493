import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { usePerioStore } from '../../zustand/perioStore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewPerioGraph from '../ViewPerioGraph';
import { Close } from '@mui/icons-material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.common.black,
    backgroundColor: 'white',
    fontWeight: 700,
    cursor: 'pointer',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const tableHeaders = [
  { id: 'ExamDate', label: 'Exam Date', numeric: false, date: true, filter: false },
  {
    id: 'Note',
    label: 'Note',
    numeric: false,
    filter: false,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ExamsListTable() {
  const { examsList, setExamsList } = usePerioStore();
  const [orderBy, setOrderBy] = useState('ExamDate');
  const [order, setOrder] = useState('asc');
  const [openDialog, setOpenDialog] = useState(false);
  const [perioDetail, setPerioDetail] = useState(null);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const onRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const showPerioGraph = async (row) => {
    setOpenDialog(true);

    setPerioDetail(row);
  };

  const filterAndSortRows = React.useCallback(() => {
    setExamsList(stableSort(examsList, getComparator(order, orderBy)));
  }, [examsList, order, orderBy, setExamsList]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setPerioDetail(null);
  };

  React.useEffect(() => {
    filterAndSortRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, orderBy]);

  return (
    <>
      <TableContainer
        sx={{
          width: '100%',
          maxHeight: 700,
          border: '1px solid rgba(0, 0, 0, .125)',
          borderTop: 'none',
        }}
      >
        <Table
          sx={{ minWidth: 700 }}
          stickyHeader
          aria-label='Perio exam lists'
        >
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <StyledTableCell
                  key={header.id}
                  align='left'
                  sortDirection={orderBy === header.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === header.id}
                    direction={orderBy === header.id ? order : 'asc'}
                    onClick={createSortHandler(header.id)}
                  >
                    {header.label}
                    {orderBy === header.id ? (
                      <Box
                        component='span'
                        sx={visuallyHidden}
                      >
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </StyledTableCell>
              ))}

              <StyledTableCell
                align='left'
                width='100px'
              >
                View
              </StyledTableCell>
            </TableRow>
          </TableHead>

          {examsList?.map((row) => (
            <TableRow key={row.name}>
              <StyledTableCell sx={{ fontWeight: 600 }}>{dayjs(row.ExamDate).format('MM/DD/YYYY')}</StyledTableCell>
              <StyledTableCell
                align='left'
                sx={{ maxWidth: 200 }}
              >
                {row.Note}
              </StyledTableCell>
              <StyledTableCell align='center'>
                <IconButton onClick={() => showPerioGraph(row)}>
                  <VisibilityIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth='md'
        fullWidth
      >
        <DialogTitle>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <Typography>
              Perio Graph {perioDetail?.ExamDate ? `(${dayjs(perioDetail?.ExamDate).format('MM/DD/YYYY')})` : ''}
            </Typography>

            <IconButton onClick={handleCloseDialog}>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>

        <DialogContent
          dividers
          sx={{ pb: '60px' }}
        >
          <ViewPerioGraph
            perioExamNum={perioDetail?.PerioExamNum}
            examDate={perioDetail?.ExamDate}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

import React, { useMemo } from "react";
import Tooth from "../Tooth";
import {
  filterDeletedTreatments,
  findTreatmentsByEoProcStatus,
  getStylesByTooth,
  hasMissingTooth,
  hasProcedureDeleted,
} from "../helper";
import naturalToothImage from "../../../assets/images/teeth/natural14.png";
import naturalCrownAreaImage from "../../../assets/images/teeth/upperView/natural/14.png";
import implant14 from "../../../assets/images/teeth/implant/root/14.png";
import crownPart14 from "../../../assets/images/teeth/crownParts/14.png";
import babyTooth14 from "../../../assets/images/teeth/babyTooth/14.png";
import babyToothMissing14 from "../../../assets/images/teeth/missing/babyToothMissing/root/14.png";
import babyCrownMissing14 from "../../../assets/images/teeth/missing/babyToothMissing/crown/14.png";
import implantCrownPart14 from "../../../assets/images/teeth/crownParts/implant/14.png";
import babyNaturalCrownArea from "../../../assets/images/teeth/upperView/natural/baby/14.png";
import notYetDeveloped from "../../../assets/images/teeth/notYetDeveloped/blank.png";
import rootCanal from "../../../assets/images/teeth/rootCanal/rootCanal.svg";
import { findingAvailableMaterials } from "../../../constants/findings/findingOptions";
import rctTrapezium from "../../../assets/images/teeth/rootCanal/rct-trapezium.png";
import { useToothInformation } from "../hooks/useToothInformation";
import naturalToothImage48 from "../../../assets/images/teeth/naturalBaby14.png";

const crownAreaImageMap = {
  missing: naturalCrownAreaImage,
  natural: naturalCrownAreaImage,
  implant: naturalCrownAreaImage,
  babyNatural: babyNaturalCrownArea,
  babyToothMissing: babyCrownMissing14,
  notYetDeveloped: notYetDeveloped,
  veneer: naturalCrownAreaImage,
  pontics: naturalCrownAreaImage,
};

const toothImageMap = {
  missing: naturalToothImage,
  natural: naturalToothImage,
  implant: implant14,
  babyNatural: babyTooth14,
  babyToothMissing: babyToothMissing14,
  notYetDeveloped: notYetDeveloped,
  veneer: naturalToothImage,
  pontics: naturalToothImage,
};

const toothImageMapPrimary = {
  missing: naturalToothImage48,
  natural: naturalToothImage48,
  notYetDeveloped: notYetDeveloped,
  veneer: naturalToothImage48,
  pontics: naturalToothImage48,
};

const crownPartImageMap = {
  natural: crownPart14,
  veneer: crownPart14,
  implant: implantCrownPart14,
  pontics: crownPart14,
};

export default function Tooth24({
  finding = "natural",
  toothNumber,
  overlayTooth,
  primary,
  insideOverlay,
  permanent,
  secondaryTooth,
}) {
  const activeTreatments =
    filterDeletedTreatments(finding);
  const treatmentsByEoProcStatus =
    findTreatmentsByEoProcStatus(
      activeTreatments
    );
  const findingsToTravers = useMemo(() => {
    if (treatmentsByEoProcStatus?.length) {
      return [{ ...treatmentsByEoProcStatus[0] }];
    }
    if (activeTreatments?.length) {
      return [{ ...activeTreatments[0] }];
    }

    return [];
  }, [
    activeTreatments,
    treatmentsByEoProcStatus,
  ]);

  const missing = hasMissingTooth(
    treatmentsByEoProcStatus || activeTreatments
  );
  const styles = getStylesByTooth(
    14,
    findingsToTravers,
    missing
  );
  const { crownType, toothType } =
    useToothInformation(
      24,
      findingsToTravers,
      missing
    );

  const renderToothImageChildren = useMemo(() => {
    if (missing) {
      return null;
    }

    const findingData = findingsToTravers?.find(
      (finding) =>
        finding?.finding === "rct" ||
        (findingAvailableMaterials[
          finding?.ProcCode
        ]?.name?.startsWith("rct") &&
          !hasProcedureDeleted(finding))
    );

    if (findingData) {
      return (
        <>
          <img
            src={rootCanal}
            alt="RCT-24"
            className="root-rct-24"
          />

          {findingAvailableMaterials[
            findingData?.ProcCode
          ]?.name === "rctPuPpf" && (
              <img
                src={rctTrapezium}
                alt="RCT-24"
                className="root-rct_pu_ppf-24"
              />
            )}
        </>
      );
    }
    return null;
  }, [findingsToTravers, missing]);

  return (
    <Tooth
      right
      overlayTooth={overlayTooth}
      tooth={toothNumber || 24}
      crownAreaImage={
        crownAreaImageMap[crownType] ||
        crownAreaImageMap.natural
      }
      toothImage={
        !insideOverlay && primary ? toothImageMapPrimary[toothType] ||
          toothImageMapPrimary.natural :
          toothImageMap[toothType] ||
          toothImageMap.natural
      }
      crownPartImage={
        crownPartImageMap[crownType]
      }
      toothWrapperClassName={
        styles[toothType]?.toothWrapper ||
        styles.natural.toothWrapper
      }
      crownAreaWrapperClassName={
        styles[crownType]
          ?.crownAreaImageWrapper ||
        styles.natural.crownAreaImageWrapper
      }
      toothImageWrapperClassName={
        styles[toothType]?.toothImageWrapper ||
        styles.natural.toothImageWrapper
      }
      crownPartClassName={
        styles[crownType]?.toothCrownPart
      }
      toothImageChildren={
        renderToothImageChildren
      }
      firstFinding={findingsToTravers[0] || {}}
      primary={primary}
      insideOverlay={insideOverlay}
      permanent={permanent}
      secondaryTooth={secondaryTooth}
    />
  );
}

export const crownUpperviewAreas = {
  mesial: 'mesial',
  distal: 'distal',
  facial: 'facial',
  top: 'top',
  oral: 'oral',
};

export const MESIAL = 'left';
export const DISTAL = 'right';
export const FACIAL = 'top';
export const LINGUAL = 'bottom';
export const ORAL = 'center';

export const readableUpperLeftCrowAreas = {
  M: 'left',
  O: 'center',
  I: 'center',
  D: 'right',
  B: 'top',
  F: 'top',
  L: 'bottom',
};

export const readableUpperRightCrowAreas = {
  M: 'right',
  O: 'center',
  I: 'center',
  D: 'left',
  B: 'top',
  F: 'top',
  L: 'bottom',
};

export const readableLowerLeftCrowAreas = {
  M: 'left',
  O: 'center',
  I: 'center',
  D: 'right',
  B: 'bottom',
  F: 'bottom',
  L: 'top',
};

export const readableLowerRightCrowAreas = {
  M: 'right',
  O: 'center',
  I: 'center',
  D: 'left',
  B: 'bottom',
  F: 'bottom',
  L: 'top',
};

export const biologicalUpperLeftCrowAreas = Object.fromEntries(
  Object.entries(readableUpperLeftCrowAreas).map(([key, value]) => [value, key])
);

export const biologicalUpperRightCrowAreas = Object.fromEntries(
  Object.entries(readableUpperRightCrowAreas).map(([key, value]) => [
    value,
    key,
  ])
);

export const biologicalLowerLeftCrowAreas = Object.fromEntries(
  Object.entries(readableLowerLeftCrowAreas).map(([key, value]) => [value, key])
);

export const biologicalLowerRightCrowAreas = Object.fromEntries(
  Object.entries(readableLowerRightCrowAreas).map(([key, value]) => [
    value,
    key,
  ])
);
